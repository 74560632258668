.company-edit {
  IMG.logo-preview {
    max-width: 320px;
    height: auto;
  }

  .reps-lists {
    UL {
      margin: 0 0 30px 0;
      padding: 0;
      list-style: none;

      LI.rep-block {
        border-bottom: 1px solid $planemover_gray;
        padding: 15px;

        .editable-input-group {
          margin: 5px 0;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
