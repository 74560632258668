@import "../variables/buttons";
@import "../variables/breakpoints";

.promotion-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);

  .popup-inner {
    display: flex;
    align-items: center;
    min-height: 100vh;
    max-height: 100vh;
    margin: auto;
    position: relative;
    width: 70%;
    min-width: 320px;
    max-width: 1000px;
  }

  .popup-modal {
    // position: absolute;
    // left: 0%;
    // right: 0%;
    // top: 0%;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow: hidden;
    padding: 15px;
    width: 100%;
    // @include for-tablet_portrait-up {
    //   top: 15%;
    // }
    // @include for-desktop-up {
    //   left: 15%;
    //   right: 15%;
    //   top: 15%;
    // }
    // margin: auto;
    background: white;

    .popup-header {
      flex-grow: 0;
      flex-shrink: 0;
      position: relative;

      .popup-close {
        position: absolute;
        right: -5px;
        top: -5px;
        display: inline-block;
        padding: 0;
        width: 32px;
        height: 32px;
        border-radius: 0;
        background: transparent !important;
        border: 0 !important;

        SVG,
        .svg {
          display: inline-block;
          width: 32px;
          height: 32px;
          fill: $planemover_gray;
        }

        &:hover,
        &:focus {
          SVG,
          .svg {
            fill: $planemover_black;
          }
        }
      }
    }
    .popup-content {
      flex-grow: 1;
      flex-shrink: 1;
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 17px;

      .promotion-edit {
        .promotion-edit-form {
          border: 1px solid $planemover_gray;
          margin-bottom: 15px;
          padding: 15px;
        }
        .promotion-edit-preview {
        }
      }

      .item-column {
        margin-bottom: 30px;
      }
      .listing-column {
        display: flex;
        justify-content: space-between;
      }
      .message-column {
        margin-bottom: 15px;
        .message {
          border: 1px solid #b2b5b6;
          padding: 15px;
          @include for-tablet_portrait-up {
            padding: 25px;
          }
        }
      }
      .actions-row {
        margin-bottom: 5px;
        .btn {
          padding: 10px;
        }
      }
    }
    .popup-footer {
      flex-grow: 0;
      flex-shrink: 0;
      padding-top: 15px;
    }
  }
}
