.payment-wrap {
  display: grid;
  grid-template-columns: repeat(4, 45px);
  gap: 10px;
  .payment-method {
    height: 32px;
    border: 1px solid #B2B5B6;
    margin: 0;
    border-radius: 4px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .5;

    &.active {
      border-color: $planemover_teal;
      opacity: 1;
    }

    &.visa {
      background-image: url('../../images/payment-icons/light-color/1.png')
    }
    &.master {
      background-image: url('../../images/payment-icons/light-color/2.png')
    }
    &.american-express {
      background-image: url('../../images/payment-icons/light-color/22.png')
    }
    &.discover {
      background-image: url('../../images/payment-icons/light-color/14.png')
    }

    input {
      opacity: 0;
    }
  }
}