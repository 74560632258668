@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/breakpoints";

.promotionPage {
  padding: 70px 0;
  font-family: $planemover_barlow;

  .desktop {
    display: none;
    @include for-tablet-portrait-up {
      display: block;
    }
  }
  .promote-mo-table {
    padding: 20px;
  }
  .schedule {
    display: block;
    .top-buttons {
      justify-content: center;
      margin-top: 50px;
      @include for-tablet-portrait-up {
        margin-top: 84px;
      }
    }
    .schedule-buttons {
      display: block;
      @include for-tablet-portrait-up {
        display: flex;
      }
      justify-content: space-between;
      margin-bottom: 17px;
      .lt-group {
        margin-top: -70px;
        margin-right: 14px;
      }
      .schedule-between-time {
        justify-content: center;
        margin-top: 50px;
        @include for-tablet-portrait-up {
          margin-bottom: 0px;
          margin-top: 0px;
        }
        margin-bottom: 50px;
        display: flex;
        align-items: flex-end;
        font-size: 20px;

        svg:nth-of-type(1) {
          transform: rotate(180deg);
        }
        svg {
          width: 15px;
          height: 15px;
          margin-bottom: 2px;
        }
      }
      .mo-toggle-btn {
        &.mobile {
          @include for-tablet-portrait-up {
            display: none;
          }
          display: flex;
          justify-content: center;
          margin-top: 40px;
          cursor: pointer;
        }
      }
      .mo-toggle-btn {
        &.desktop {
          justify-content: center;
          margin-top: 13px;
          cursor: pointer;
        }
      }
      .toggle-btn {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 5px;
        cursor: pointer;
        div {
          width: 53px;
        }
      }
    }
    .mo-title-item-label {
      @include for-tablet-portrait-up {
        display: none;
      }
      margin-top: 3px;
      font-weight: 500;
      font-size: 25px;
      line-height: 16px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 40px;
      &.mo-selected-lb {
        font-style: bold;
        color: $planemover_red;
      }
    }
  }
  .mobile {
    @include for-tablet-portrait-up {
      display: none;
    }
    display: block;
    &__lt-group {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      .btn-new {
        width: 141px;
        height: 30px;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 5px;
        a {
          color: $planemover_white;
        }
      }
    }
    &__list-box {
      margin-top: 45px;
      display: flex;
      justify-content: center;
      .view-label {
        margin-right: 8px;
        margin-top: 5px;
      }
    }

    .top-buttons {
      justify-content: center;
      margin-top: 50px;

      .trash-rectangle,
      .mark-unread-rectangle,
      .mark-read-reactangle {
        height: 45px;
      }
      .trash-rectangle {
        width: 81px;
        .top-buttons-lb {
          margin-top: 7px;
        }
      }
      .mark-unread-rectangle {
        width: 115px;
        .top-buttons-lb {
          margin-top: 7px;
        }
        .mo-mark-unread-logo {
          margin-top: 12px;
          margin-left: 18.1px;
        }
      }
      .mark-read-reactangle {
        width: 101px;
        .mark-read-logo {
          margin-top: 6px;
          margin-left: -3px;
        }
        .top-buttons-lb {
          margin-top: 7px;
          margin-left: 0px;
        }
      }
    }

    .promotion-tb {
      margin-bottom: 8px;
      margin-left: 1px;

      &.mobile {
        margin-right: 1px;
        margin-top: 50px;
      }
      .mo-tb-name-lb {
        width: 100%;
        border: 1px solid #b2b5b6;
        height: 35px;
        .checkbox-wrapper {
          margin-top: 8px;
          margin-left: 11px;
        }
        .check-all-lb {
          margin-left: 42px;
          height: 14px;
          font-size: 14px;
          text-transform: uppercase;
          color: $planemover_gray;
          display: flex;
          align-items: center;
        }
      }
    }
    .mo-tb-item {
      margin-left: 0px;
      display: grid;
      margin-top: -1px;
      margin-right: 1px;
      .mo-hour {
        text-align: right;
      }
      .mo-tb-box {
        display: flex;
        font-size: 14px;
        .mo-name {
          font-size: 18px;
        }
        .avatar-box {
          .avatar {
            width: 35px;
            height: 35px;
          }
        }
        .avatar-box,
        .info {
          margin-top: 11px;
        }
      }
    }
    .tb-name-item {
      width: 100%;
      .checkbox-wrapper {
        margin-top: 0px;
        margin-left: 0px;
      }
    }
    .mo-read-date {
      margin-left: -40px;
      span:nth-of-type(2) {
        float: left !important;
      }
    }
    .mo-title-menu {
      display: flex;
      margin-top: 60px;
      height: 48px;
      left: 389px;

      text-align: center;
      padding-left: 10px;
      &__underline {
        border-bottom: 1px solid $planemover_gray;
        width: 94%;
        position: absolute;
        margin-top: 52px;
      }
      &__item {
        width: 16%;
        height: 26px;
        cursor: pointer;
        .mo-selected-underline {
          width: 100%;
          height: 10px;
          margin-top: 20px;
          background: $planemover_red;
        }
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(6),
        &:nth-of-type(7) {
          .mo-selected-underline {
            margin-top: 17px;
          }
        }
        &:nth-of-type(5) {
          .mo-selected-underline {
            margin-top: 14px;
          }
        }
      }
    }
    .mo-title-item-label {
      @include for-tablet-portrait-up {
        display: none;
      }
      margin-top: 3px;
      font-weight: 500;
      font-size: 25px;
      line-height: 16px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 40px;
      &.mo-selected-lb {
        font-style: bold;
        color: $planemover_red;
      }
    }
  }
  .promotion-nav {
    @include for-desktop-up {
      display: contents;
    }
    display: none;
  }

  .all {
    margin-left: 20px;
    @include for-tablet-portrait-up {
      margin-left: 0px;
    }
    @include planemover_font(30px, 19.2px, 400);
    color: $planemover_red;
    padding-bottom: 20px;
    .bold {
      font-weight: bold;
    }
  }
  .md-promotion-nav {
    @include for-desktop-up {
      display: none;
    }
  }

  .title-menu {
    @include for-tablet-portrait-up {
      display: flex;
      margin-left: 30px;
    }
    display: none;
    margin-left: 110px;
    height: 48px;
    left: 389px;
    width: 70%;
    text-align: center;
    &__item {
      width: 18%;
      height: 26px;
      cursor: pointer;
      .title-lb {
        margin-top: 3px;
        font-weight: 500;
        font-size: 18px;
        line-height: 16px;
        text-transform: uppercase;
      }
      .selected-lb {
        font-style: bold;
        color: $planemover_red;
      }
      .selected-underline {
        height: 10px;
        margin-top: 19px;
        background: $planemover_red;
      }
    }
  }

  .top-buttons {
    margin-top: 94px;
    display: flex;
    .btn-outline-secondary {
      border-radius: 0px;
      border: 1px solid $planemover_gray;
    }
    .toggle-btn {
      background: $planemover_white;
      padding-left: 10px;
      padding-top: 5px;
      letter-spacing: inherit;
      cursor: pointer;
    }
    .toggle-btn.active {
      &.trash-rectangle,
      &.mark-unread-rectangle,
      &.mark-read-reactangle {
        background-color: unset;
        .top-buttons-lb {
          color: $planemover_gray;
        }
      }
      background-color: $planemover_teal;
      .top-buttons-lb {
        color: $planemover_white;
      }
    }

    .toggle-btn:hover {
      &.trash-rectangle,
      &.mark-unread-rectangle,
      &.mark-read-reactangle {
        background-color: unset;
      }
      background-color: $planemover_teal;
    }
    .trash-rectangle {
      width: 75px;
      height: 40px;
      border: 1px solid #b2b5b6;
      display: flex;
      .svg-button {
        margin-left: -8px;
      }
      .top-buttons-lb {
        margin-left: 2px;
        margin-top: 5px;
        width: 36px;
        height: 17px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: $planemover_gray;
      }
    }
    .mark-unread-rectangle {
      width: 117px;
      height: 40px;
      border: 1px solid #b2b5b6;
      margin-left: -1px;
      display: flex;
      padding-right: 0px;
      .svg-button {
        width: 20px;
        height: 22px;
        margin-top: 3px;
        margin-left: -6px;
      }
      .top-buttons-lb {
        margin-top: 5px;
        margin-left: 4px;
        width: 82px;
        height: 17px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: $planemover_gray;
      }
    }
    .mark-read-reactangle {
      width: 101px;
      height: 40px;
      border: 1px solid #b2b5b6;
      margin-left: -1px;
      display: flex;
      padding-right: 0px;
      .svg-button {
        width: 18px;
        height: 18px;
        margin-top: 4px;
        margin-left: -5px;
      }
      .top-buttons-lb {
        margin-left: 2px;
        margin-top: 5px;
        width: 70px;
        height: 17px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: $planemover_gray;
      }
    }
  }
  .lt-group {
    float: right;
    position: absolute;
    right: 0;
    margin-top: 10px;
    .btn-new {
      width: 80px;
      letter-spacing: 0.1em;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      padding: 4px 22px;
      display: flex;
      align-items: center;
      text-align: center;
      a {
        color: $planemover_white;
      }
    }
  }
  .list-box {
    display: flex;
    position: absolute;
    margin-top: 98px;
    right: 0px;
    .view-label {
      margin-right: 8px;
      margin-top: 5px;
    }
  }

  .promotion-tb {
    margin-top: 15px;
    height: 27px;
    box-sizing: border-box;
    .tb-name-lb {
      width: 40%;
      border: 1px solid #b2b5b6;
      height: 27px;
      .checkbox-wrapper {
        margin-top: 5px;
        margin-left: 27px;
      }
      .check-all-lb {
        margin-left: 57px;
        height: 14px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-transform: uppercase;
        color: $planemover_gray;
        display: flex;
        align-items: center;
      }
    }
    .tb-type-lb {
      width: 22%;
      border: 1px solid #b2b5b6;
      height: 27px;
      margin-left: -1px;
    }
    .tb-state-lb {
      width: 38%;
      border: 1px solid #b2b5b6;
      height: 27px;
      margin-left: -1px;
    }
  }

  .checkbox-wrapper {
    width: 10px;
    position: relative;
    .checkbox {
      display: none;
    }
  }
  .checkbox-wrapper label {
    cursor: pointer;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    left: 0;
    background: $planemover_white;
    border: 1px solid #b2b5b6;
  }
  .checkbox-wrapper label:after {
    opacity: 0;
    content: "";
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 6px;
    left: 7px;
    border: 3px solid #333;
    border-top: none;
    border-right: none;

    transform: rotate(-45deg);
  }
  .checkbox-wrapper label:hover::after {
    opacity: 0;
  }
  .checkbox-wrapper input[type="checkbox"]:checked + label:after {
    opacity: 1;
    top: 3px;
    left: 2px;
  }
  .tb-name-item {
    width: 40%;
    border: 1px solid #b2b5b6;

    .mark {
      width: 12px;

      background: #fff0;
    }
    .item-content {
      width: calc(100% - 12px);
      padding: 12px 28px 12px 12px;
      & > div {
        padding: 0px 6px;
      }
      .checkbox-wrapper {
        margin-top: 15px;
        margin-left: 3px;
      }

      .avatar-box {
        margin-left: 9px;
        .avatar {
          margin-top: 8px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: $planemover_teal;
          text-align: center;
          font-size: 19px;
          color: $planemover_white;
          font-weight: bold;
          line-height: 1.5;
        }
      }
      .info {
        padding-left: 9px;
        width: 100%;
        div {
          &:nth-of-type(2),
          &:nth-of-type(3) {
            span:nth-of-type(1) {
              font-weight: normal;
              font-size: 15px;
            }
          }

          span:nth-of-type(2) {
            float: right;
          }

          a {
            color: $planemover_teal;
            text-decoration: underline;
            font-weight: normal;
          }
        }
      }
    }
    &.unread {
      .unread-mark {
        background: $planemover_red;
      }
      .info {
        font-weight: bold;
      }
    }
  }
  .tb-type-item {
    width: 22%;
    border: 1px solid #b2b5b6;
    margin-left: -1px;
    .item-content {
      width: calc(100% - 12px);
      padding: 12px 12px 12px 12px;
      div {
        &:nth-of-type(2),
        &:nth-of-type(3) {
          span {
            font-weight: normal;
            font-size: 15px;
          }
        }
      }
    }
    &.unread {
      .item-content {
        font-weight: bold;
      }
    }
  }
  .tb-state-item {
    width: 38%;
    border: 1px solid #b2b5b6;
    margin-left: -1px;
    .item-content {
      width: calc(100% - 12px);

      padding: 12px 12px 12px 32px;
      div {
        &:nth-of-type(2),
        &:nth-of-type(3) {
          span {
            font-weight: normal;
            font-size: 15px;
          }
        }
      }
    }
    &.unread {
      .item-content {
        font-weight: bold;
      }
      .alert-unread {
        color: $planemover_red;
      }
    }
  }

  .react-calendar-timeline {
    border: 1px solid #c4c4c4;
    margin-left: -15px;
    margin-right: -15px;
    @include for-tablet-portrait-up {
      margin-left: 0px;
      margin-right: 0px;
    }
    .rct-sidebar {
      border-right: 1px solid #bbb !important;
      .rct-sidebar-row {
        font-size: 18px;
        svg {
          width: 18px;
          height: 18px;
          float: right;
          opacity: 0.5;
          margin-top: 6px;
        }
      }
      .rct-sidebar-row-odd {
        background-color: $planemover_white;
      }
    }
    .rct-horizontal-lines {
      .rct-hl-odd {
        background: $planemover_white;
      }
    }
    .rct-outer {
      .rct-scroll {
        border-left: 0px solid #c4c4c4;
      }
    }
    .rct-items {
      .rct-item {
        border-radius: 30px !important;
      }
    }
    .rct-header-root {
      background: $planemover_white;
      margin-left: 1px;
      div:nth-of-type(1) {
        width: 179px !important;
      }
      div {
        border-bottom: 0px solid #c4c4c4;
        border-right: 1px solid #c4c4c4 !important;
        border-top: 0px;
        border-left: 0px;
      }
      .rct-dateHeader {
        border-bottom: 0px solid #c4c4c4;
        border-top: 0px solid #c4c4c4;
        background-color: $planemover_white;
      }
      .rct-calendar-header {
        margin-left: 1px;
      }
    }
  }
}

.promotion-page {
  &__title {
    @include planemover_page_title;
    color: $planemover_red;

    & span {
      font-weight: 600;
    }
  }

  header {
    .action-buttons {
      margin-bottom: 15px;
      .btn {
        padding: 10px;
      }
    }
  }

  .inbox-table {
    margin: 0 15px;
    .header {
      border: 1px solid $planemover_gray;
      padding: 5px 0 5px 15px;
      color: $planemover_gray;
      .form-group {
        margin: 0;
        line-height: 26px;
        text-transform: uppercase;
        input {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
        .form-check-label {
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
    .item-row {
      cursor: pointer;
      margin-right: -15px;
      margin-left: -15px;
      border: 1px solid $planemover_gray;
      border-top: 0;
      @include planemover_font(14px, 1rem);

      &.flagged {
        background-color: $planemover_teal-white;
      }
      .column-wrapper {
        display: flex;
        flex-wrap: wrap;
        //border-left: 15px solid $planemover_white;
        padding: 10px 0 10px 15px;
        width: 100%;
      }
      .item-column {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .select-column {
        padding-right: 0;

        input {
          width: 16px;
          height: 16px;
          margin-bottom: 10px;
        }

        .svg {
          fill: $planemover_teal;
          max-width: 18px;
        }

        .time-column {
          text-align: right;
          .time {
            padding-right: 15px;
          }
        }
      }

      .listing-column {
        .row {
          display: flex;
          justify-content: space-between;
        }
      }

      &.status-unread {
        .column-wrapper {
          border-left: 15px solid $planemover_red;
          padding-left: 0 !important;
        }
        .from-name,
        .listing-column,
        .to-column,
        .message-column {
          font-weight: bold;
        }

        .status.label {
          text-transform: uppercase;
          color: $planemover_red;
        }
      }
    }
  }

  .filter-dropdown {
    .form-control {
      width: auto;
      display: inline-block;
      margin-left: 10px;
    }
  }

  .pagination {
    margin-left: 30px;
  }

  .schedule {
    .btn-group-toggle {
      margin-bottom: 15px;

      .toggle-btn {
        padding: 0.5em 1em;
      }
    }

    .react-calendar-timeline {
      border: 1px solid $planemover_gray;
      .rct-header-root {
        background: transparent;
        background-color: $planemover_teal;

        .rct-calendar-header {
          border: 0;

          .rct-dateHeader {
            //color: $planemover_dark;
          }
        }
      }
      .rct-sidebar {
        .rct-sidebar-row {
          SVG {
            padding: 5px;
            fill: $planemover_gray;
            float: right;
          }
          .has-children {
            cursor: pointer;
          }
          .depth-0 {
            font-weight: 500;
          }
        }
      }
      .rct-vertical-lines {
        .rct-vl.rct-day-0,
        .rct-vl.rct-day-6 {
          background: rgba($planemover_gray, 0.25) !important;
        }
      }
      .rct-item {
        border-radius: 50px;
        border: 0 !important;
        padding: 0 5px;
        background: $planemover_red !important;

        &.plan-silver {
          background: $planemover_silver !important;
        }
        &.plan-gold {
          background: $planemover_gold !important;
        }
        &.plan-platinum {
          background: $planemover_platinum !important;
        }
        &.plan-titanium {
          background: $planemover_titanium !important;
        }

        &:focus,
        &:active {
          background: $planemover_red !important;
        }
      }
    }
  }
}

.promotion-popup {
  .header-row {
    .label,
    .value {
      font-size: 0.85rem;
    }
  }
}

.promotionsNewPage {
  padding-top: 50px;
  @include for-tablet-portrait-up {
    padding: 120px 0;
  }
  font-size: 18px;
  color: $planemover_dark;

  .errors {
    background-color: rgba($planemover_red, 0.1);
    border: 1px solid $planemover_red;
    margin-right: 15px;
    margin-top: 15px;
    padding: 10px;

    UL {
      margin: 0;
      padding: 0;
      list-style: none;

      LI {
        margin: 5px 0;
        font-weight: 500;
        color: $planemover_red;
      }
    }
  }

  .promote-new-head {
    padding: 0px 20px 0px 40px;
    @include for-tablet-portrait-up {
      padding: 0px 20px 0px 5px;
    }
    .md-block {
      display: block;
      @include for-tablet-portrait-up {
        display: flex;
      }
    }
  }

  .form-group {
    align-items: center;
    .form-label {
      font-size: 17px;
      font-weight: 400;
      text-transform: uppercase;
      margin: 0 8px 0 0;
      white-space: nowrap;
    }

    .form-control {
      padding: 5px;
      line-height: 1;
      height: auto;
    }
  }
  .container {
    // padding-left: 0px;
    // padding-right: 0px;
    // .col {
    //     padding-left: 0px;
    //     padding-right: 0px;
    // }
    // .row {
    //     @include for-tablet-portrait-up {
    //         padding: 0px -15px 0px -15px;
    //     }
    // }
  }
  .mobile-availabel-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    @include for-tablet-portrait-up {
      justify-content: end;
    }
    margin-left: auto;
    margin-right: auto;
    button {
      width: auto;
    }
  }
  .md-dis-show {
    display: block;
    @include for-tablet-portrait-up {
      display: none;
    }
    .list-group-item {
      text-align: left;
    }

    .bg-transparent-300 {
      width: 206px;
    }

    .btn-group-toggle {
      display: block !important;
      .btn {
        width: 100px;
      }
    }
    .list-btn-tgls {
      display: flex;
      .btn-group-toggle {
        display: block;
      }
    }
  }
  .md-dis-none {
    @include for-tablet-portrait-up {
      display: flex;
    }
    display: none;
  }

  .promote-new-img {
    margin-top: 20px;
  }
  .mobile-calendar-appear {
    @include for-tablet-landscape-up {
      display: none;
    }
    display: contents;
  }
  .mobile-calendar-disappear {
    @include for-tablet-landscape-up {
      display: contents;
    }
    display: none;
  }
  @include for-tablet-landscape-up {
    .Calendar-div {
      //margin-top: 179px;
    }
    .select-channel {
      display: block !important;
    }
    .mobile-calendar-disappear {
      display: block;
    }
    .mobile-block {
      display: flex !important;
    }
  }
  @include for-desktop-up {
    .Calendar-div {
      //margin-top: 71px;
    }
    .mobile-calendar-disappear {
      display: block;
    }
  }
  .sm-title {
    font-size: 20px;
    font-weight: 600;
  }
  .btn-primary {
    height: auto;
    width: auto;
  }
  .promote-head-text {
    display: block;
    .strong {
      font-size: 18px;
      font-weight: 600;
    }
  }
  h6 {
    font-size: 17px;
    font-weight: 500;
  }

  label {
    font-size: 16px;
    font-weight: 400;
    margin-top: 1px;
  }
  .text-green {
    color: $planemover_teal;
  }

  .mobile-calendar {
    display: none;
  }
  .promote-new-head {
    margin-bottom: 40px;
    .title {
      line-height: 36px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: $planemover_red;
      font-size: 30px;
    }
  }

  .bg-transparent-206 {
    width: 206px;
  }
  .bg-transparent-130 {
    width: 130px;
  }
  .bg-transparent-300 {
    width: 300px;
  }
  .bg-transparent-206,
  .bg-transparent-300,
  .bg-transparent-130,
  .bg-transparent-206 {
    height: 25px;
  }
  small {
    font-size: 14px;
  }
  .title-menu {
    display: flex;
    margin-left: 110px;
    height: 48px;
    left: 389px;
    top: 225px;
    text-align: center;
    .title-item {
      width: 180px;
      height: 26px;
      .title-label {
        margin-top: 3px;
        font-weight: 500;
        font-size: 18px;
        line-height: 16px;
        text-transform: uppercase;
      }
      .selected-label {
        font-style: bold;
        color: $planemover_red;
      }
      .selected-underline {
        width: 180px;
        height: 10px;
        margin-top: 19px;
        background: $planemover_red;
      }
    }
  }
  .tab-content {
    .feature-detail {
      padding: 18px 30px 22px 38px !important;
    }
  }
  .list-group-item.active {
    color: $planemover_gray;
    background-color: $planemover_gray-light !important;
    border: none;
  }
  .list-group-item {
    letter-spacing: 0rem;
    font-weight: 400;
    text-transform: none;
    padding: 16px 18px 14px 16px;
    border: 2px solid rgba(196, 196, 196, 0.1);
    text-decoration: unset !important;
  }
  .list-group-item:nth-child(odd) {
    background-color: $planemover_gray-white;
  }

  .list-group-item:first-child,
  .list-group-item:last-child {
    border-radius: unset;
  }
  .list-item-text {
    font-family: Barlow Condensed;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    color: $planemover_dark;
    .promote-head-text {
      margin-bottom: 8px;
      small {
        font-size: 15px;
        font-weight: 500 !important;
      }
    }
  }

  .list-checkbox {
    -webkit-appearance: none;
    background-color: $planemover_white;
    border: 1px solid $planemover_gray;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7.5px;
    display: inline-block;
    position: relative;
    height: 15px;
  }
  .list-checkbox:active,
  .list-checkbox:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .list-checkbox:checked {
    background-color: $planemover_gray-white;
    border: unset;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: $planemover_teal;
    background-color: $planemover_teal;
  }
  .list-checkbox:checked:after {
    content: "\2714";
    font-size: 10px;
    position: absolute;
    top: -3px;
    left: 3px;
    color: $planemover_white;
  }

  .checkbox {
    display: inline-block;
    padding: 0;
    height: 32px;
    width: 32px;
    margin-right: 10px;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .tab-pane {
    display: none;
    position: relative;

    &.active {
      display: block !important;
    }
  }

  .tab-selectors {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .tab-selectors {
      display: flex;
    }

    .tab-content .list-group-item {
      display: none !important;
    }
  }

  .form-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba($planemover_gray-light, 0.75);
  }
  .feature-detail {
    background-color: $planemover_gray-light;
    min-height: 100%;
    .text-uppercase {
      font-size: 15px;
      font-weight: 400;
    }

    h6 {
      text-transform: initial;
    }
  }

  .disclaimer {
    max-width: 600px;
    margin-bottom: 40px;

    H4 {
      text-transform: none;
      font-weight: bold;
    }
    P {
      font-style: italic;
    }
  }
}
.toggle-btn {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding: 2px 5px;
  text-transform: none;
  flex: unset !important;
  border-radius: 0px;
  background-color: unset;
  letter-spacing: 0rem;
  color: $planemover_black;

  &:hover {
    color: $planemover_white !important;
    background-color: $planemover_teal !important;
    border: 1px solid $planemover_teal !important;
  }
  &.active {
    color: $planemover_black !important;
    background-color: unset !important;
    border: 1px solid $planemover_teal !important;
    box-shadow: 0 0 0px 1px $planemover_teal;
  }
}

.Calendar-div {
  padding: 5px;
  .daypicker-head {
    width: 100%;
    height: 24px !important;
    background-color: $planemover_teal;
  }

  .Range {
    background-color: $planemover_white;
    width: 100%;
    .DayPicker-Day {
      border-radius: 0% !important;
      padding: 5px 10px !important;
    }
    .DayPicker-Day.DayPicker-Day--disabled {
      //color: $planemover_black-dark;
    }
    .DayPicker-Day.DayPicker-Day--start {
      background-color: $planemover_teal !important;
      border-bottom-left-radius: 50% !important;
      border-top-left-radius: 50% !important;
    }
    .DayPicker-Day.DayPicker-Day--end {
      background-color: $planemover_teal !important;
      border-bottom-right-radius: 50% !important;
      border-top-right-radius: 50% !important;
    }
    .DayPicker-Day--selected {
      background-color: $planemover_teal !important;
      color: $planemover_white;
    }
    .DayPicker-Month {
      margin: 0.5em 1em;
      width: 100%;
    }
    .DayPicker-Caption {
      padding: 0 1em;
    }
  }

  .calendar-buttons {
    padding-bottom: 10px !important;
  }

  .btn-calendar,
  .btn-calendar:active,
  .btn-calendar:focus {
    background-color: unset !important;
    color: $planemover_teal !important;
    border: none !important;
    border-radius: 0 !important;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    outline: none;
  }
}
.promotion-calendar {
  min-width: 240px;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}
