.pagination {
	margin: 0;
	border-radius: 0;
	.page-item {
		margin: 0 10px;
		.page-link {
			border-radius: 0;
			border: 0;
			padding: 5px;
			color: $planemover_black;
			border-bottom: 2px solid transparent;
			background-color: transparent !important;
			box-shadow: none;
			outline: 0;
		}

		&.active {
			.page-link {
				font-weight: bold;
				background-color: transparent;
				border-color: $planemover_teal;
			}
		}
	}
}
