.account-settings {
  .title {
    @include planemover_page_title;
    color: $planemover_red;
    margin-bottom: 30px;

    .bold {
      font-weight: 600;
    }
  }

  .subtitle {
    @include planemover_subheader;
    margin-bottom: 26px;
  }

  .profile {
    .profile-preview {
      max-width: 150px;
    }
    .footer {
      margin-top: 65px;
    }

    .password-form {
      margin-top: 15px;
      padding: 20px;
      border: solid 1px $planemover_gray;
      display: flex;
      flex-direction: column;

      &-group {
        @include planemover_text;
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }

        .form-label {
          margin-right: 10px;
          margin-bottom: 0;
          &-star {
            color: $planemover_teal;
            margin-bottom: 0;
          }
        }

        .invalid-feedback {
          @include planemover_sidebar_subheader;
          color: $planemover_red;
        }

        .form-control {
          height: 25px;
          padding: 0 5px;
          border: solid 1px $planemover_gray;
          outline: none;
          color: $planemover_black;
          max-width: 150px;
          @include planemover_sidebar_subheader;

          &.is-invalid {
            background-image: unset;
          }
        }
      }

      &-save {
        padding-top: 20px;
        text-align: right;
      }

      @include for-tablet-portrait-up {
        padding: 10px 15px;
        width: fit-content;
        flex-direction: row;
        align-items: flex-end;

        &-save {
          padding-top: 0px;
          padding-left: 20px;
          text-align: unset;
        }
      }
    }
  }

  .email {
    .email-form-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }

    .email-form {
      margin: 25px 0;

      .form-check-input {
        margin-top: 0.2rem;
      }
    }
  }
}

.card-decryption-modal {
  TEXTAREA {
    font-size: 18px;
    line-height: 1;
    letter-spacing: 1px;
    text-align: center;
    padding: 15px;
    max-height: 100%;
    min-height: 360px;
  }

  &.card-details {
    .card-details-list {
      list-style: none;
      margin: 0;
      padding: 0;

      LI {
        text-align: left;
        line-height: 1;
        margin-bottom: 30px;

        .label {
          display: block;
          font-size: 16px;
        }

        INPUT {
          display: block;
          width: 100%;
          padding: 10px;
        }
      }
    }
  }
}
