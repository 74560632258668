$planemover_red-light: rgba(109, 0, 32, 0.5);
$planemover_red: #6d0020;
$planemover_red-ligthdark: #540119;
$planemover_red-dark: #2b010d;
$planemover_dark: #000000;
$planemover_black-dark: #212529;
$planemover_black: #25282a;
$planemover_teal-light: rgba(0, 148, 144, 0.5);
$planemover_teal-white: #e6f5f4;
$planemover_teal: #009490;
$planemover_teal-ligthdark: #017774;
$planemover_teal-dark: #01504e;
$planemover_gray-white: rgba(196, 196, 196, 0.1);
$planemover_gray-light: rgba(37, 40, 42, 0.2);
$planemover_gray_light: #ececec;
$planemover_gray: #b2b5b6;
$planemover_gray-ligthdark: #9c9c9c;
$planemover_gray-dark: #787878;
$planemover_gray-extradark: #6b757f;
$planemover_white: #fff;

$planemover_silver: #bab8b8;
$planemover_gold: #ffd056;
$planemover_platinum: #646262;
$planemover_titanium: #a8b3cf;
