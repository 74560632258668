body.unauthorized-page {
  background-color: $planemover-red;

  .app-header {
    background-color: transparent;
  }

  .app-unauthorized {
    padding: 10vh 0;

    h1 {
      color: $planemover_white;
    }

    .airplane-graphic {
      margin-left: 5vw;
    }
    .svg,
    svg {
      fill: $planemover_gray;
      width: 100%;
      max-width: none;
      height: auto;
    }

    .login-form-container {
      padding: 30px 50px;
      background-color: $planemover_white;
    }
  }
}
