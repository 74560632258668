@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/breakpoints";

// Frontend Tables
TABLE {
  &.unwide {
    width: auto !important;
  }
  &.se-table-size-100 {
    width: 100%;
  }
  &.se-table-layout-auto,
  &.table-bordered,
  &.table-striped {
    THEAD {
      color: $planemover_red;
      font-weight: bold;

      TH,
      TD {
        border: 1px solid $planemover_gray;
        padding: 0.5rem;
      }
    }

    tbody tr {
      &:nth-of-type(odd) {
        background-color: $planemover_gray_light;
      }
      &:nth-of-type(even) {
        background-color: $planemover_white;
      }
      &:hover {
        background-color: $planemover_gray !important;
      }

      TH,
      TD {
        border: 1px solid $planemover_gray;
        padding: 0.5rem;
      }
    }
  }
}

// Backend Tables
.table-layout {
  .text-red {
    color: $planemover_red;
  }

  .text-green {
    color: $planemover_teal;
  }

  .dollar {
    width: 100%;
    text-align: center;
    color: $planemover_teal;
  }

  .table-header {
    background: $planemover_gray;
    padding: 10px;
    .table-actions,
    .table-search {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .table-actions {
      select {
        width: 120px;
        height: auto;
        border-radius: 0;
        padding: 2px 5px;
        align-self: center;
        text-transform: uppercase;
        font-weight: 500;

        span {
          text-transform: capitalize;
        }
      }
    }
    .table-search {
      form {
        display: flex;
        flex-direction: row;
        svg {
          color: $planemover_gray-extradark;
          fill: $planemover_gray-extradark;
          margin-left: 5px;

          &:hover {
            color: $planemover_dark;
            fill: $planemover_dark;
          }
        }
        input {
          padding: 2px 5px;
          height: auto;
          border-radius: 0;
          width: 120px;
        }
      }
    }

    &.search {
      justify-content: flex-end;
    }

    &.search,
    &.drop-down {
      > div {
        font-family: $planemover_barlow;
        align-self: center;
        margin: 0;
      }

      label {
        margin: 0;
      }
    }
  }

  .table,
  table {
    font-family: $planemover_barlow;
    // height: 400px;
    margin: 0;
    // display: flex;
    // flex-direction: column;
    // height: 100%;

    &.table-bordered {
      border: 0;
    }

    &.table-striped {
      tbody tr {
        &:nth-of-type(even) {
          background-color: $planemover_gray_light;
        }
        &:nth-of-type(odd) {
          background-color: $planemover_white;
        }
        &:hover {
          background-color: $planemover_gray !important;
        }
      }
    }

    thead {
      //display: block;
      //padding-right: 15px;
      background: $planemover_black;

      th {
        background: $planemover_black;
        color: $planemover_white !important;
        text-transform: uppercase;
        @include planemover_text();
        font-weight: 600;

        &.sortable {
          cursor: pointer;

          .no-cursor {
            cursor: default;

            .filter-trigger {
              cursor: pointer;
            }
          }
        }

        .order {
          .dropdown .caret:before {
            content: "\2191";
            opacity: 1;
          }
          .dropup .caret:before {
            content: "\2193";
            opacity: 1;
          }
        }
        .react-bootstrap-table-sort-order .caret {
          &:after {
            content: "\2193";
            opacity: 1;
          }

          &:before {
            content: "\2191";
            opacity: 1;
          }
        }
        // & span {

        // }
      }
    }

    tr {
      td,
      th {
        padding: 0.2rem 0.5rem;
        border: 0;
        border-right: 2px solid $planemover_white;
        vertical-align: middle;

        &:last-child {
          border: 0;
        }

        & .bar svg {
          align-self: center;
          margin-right: 5px;
          margin-top: 1px;
        }

        input {
          background: transparent;
          border: 0;
        }
      }

      .user-avatar-cell {
        flex-direction: row;
        display: flex;
        justify-items: center;
        align-items: center;

        .app-avatar {
          margin-right: 10px;

          .UserAvatar--inner {
            box-shadow: 0 2px 5px rgba($planemover_black, 0.25);
          }
        }
      }

      .row-actions {
        justify-content: end;
        text-align: right;
        padding: 10px;
        .btn {
          margin-left: 5px;
        }
      }

      .svg {
        width: 16px;
        margin: auto;
        display: block;
        height: 22px;
        fill: $planemover_gray-dark;

        &.check {
          fill: $planemover_red;
        }
        &.dollar {
          fill: $planemover_teal;
        }
        &.archive {
          fill: $planemover_gray-dark;
        }
      }

      .icon-with-label {
        display: block;
        .svg {
          display: inline-block;
          margin-right: 6px;
        }
      }
    }
  }

  .table-footer {
    background: $planemover_gray;
    padding: 5px 10px;
    .table-stats,
    .table-pagination {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .table-stats {
      align-self: center;
      font-size: smaller;
      font-weight: 600;
    }
  }
}

#admin-table-popover-positioned-bottom {
  .popover-body {
    width: 250px;
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

#table-sales,
#table-sales-archive {
  .custom-col {
    flex-grow: 0;

    &.w-30 {
      min-width: 32px;
    }
  }

  .grow-width {
    flex-grow: 0 !important;
    min-width: 75px;
    padding: 0 !important;
    padding-left: 0.3rem !important;

    &.unpublished {
      min-width: 105px;
    }
  }

  thead,
  tbody tr {
    th,
    td {
      padding: 0.2rem;
      padding-left: 0.4rem;
      font-size: medium;
    }

    th:first-child,
    td:first-child {
      flex-grow: 0 !important;
    }
  }
}

#table-sales-info {
  .react-bootstrap-table {
    height: auto;

    // .table-bordered {
    //   border: 1px solid #dee2e6;
    // }

    table {
      display: table;

      thead {
        display: table-header-group;
      }

      tbody {
        overflow-y: auto;
      }
    }
  }
}

.react-bootstrap-table {
  overflow-x: auto;
}
