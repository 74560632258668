.listings {
  .listings-title {
    color: $planemover_red;
  }

  // Loading
  &.listings-loading {
    padding-top: 10vh;
    padding-bottom: 10vh;
    text-align: center;

    .spinner-border {
      color: $planemover_red;
    }
  }

  &.listings-results {
    .listings-header {
      margin-bottom: 30px;

      .listing-mobile-filter-trigger {
        padding: 5px;
        background: $planemover_gray;
        border-radius: 5px;
        border: 0;
        align-self: center;
        .svg,
        svg {
          width: 28px;
          height: 28px;
        }
      }

      .form-group {
        margin-bottom: 10px;
        .form-control {
          width: auto;
          display: inline-block;
          margin-left: 10px;
        }
      }
    }

    .listings-items {
    }

    .listings-footer {
      text-align: center;
      .paging-control {
        display: inline-block;
        .form-label {
          margin-right: 15px;
        }
        .form-control {
          display: inline-block;
          width: auto;
        }
      }

      .pagination {
        text-align: center;
        justify-content: center;
      }

      @media all and (min-width: 768px) {
        text-align: right;
        .paging-control {
          margin-right: 30px;
          .form-group {
            margin: 0;
          }
        }
        .pagination {
          float: right;
        }
      }
    }
  }

  .listings-error {
  }

  &.for-sale-listings {
  }
  &.wanted-listings {
  }
  &.services-listings {
  }
}

.btn-favorite {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;

  &:focus {
    outline: none !important;
  }

  svg,
  .svg {
    fill: $planemover_white;

    &.heart {
      fill: $planemover_gray;
    }

    &.heart-filled {
      fill: $planemover_red;
    }
  }
}

.list-item {
  margin-bottom: 80px;

  .listing-featured {
    @include planemover_font(18px, 1.5, 600);
    background-color: $planemover_red;
    color: $planemover_white;
    padding: 5px 10px;
  }

  .listing-image {
    background-color: $planemover_gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
  }

  .listing-header {
    margin-bottom: 10px;

    .listing-title {
      display: flex;
      h3 {
        @include planemover_font(24px, 29px, 600);
        width: auto;
        flex-grow: 1;
        max-width: 100%;
        display: inline-block;
        margin: 0;

        a {
          color: $planemover_red;
          &:hover {
            color: $planemover_teal;
          }
        }
      }
      button {
        flex-basis: 0;
        max-width: 100%;
        margin: 0 10px 0 0;
        border: none;
        background: none;
        padding: 0;
        outline: none;
      }
    }
  }
  .listing-content {
    margin-bottom: 20px;
  }
  .listing-meta {
    margin-bottom: 20px;
    font-size: 17px;
    .meta-row {
      margin-bottom: 3px;

      .meta-label {
        text-transform: uppercase;
        display: inline-block;
        margin-right: 5px;
      }
      .meta-value {
        font-weight: bold;
        text-transform: uppercase;
      }

      &.meta-registration {
        .meta-value {
          text-transform: uppercase;
        }
      }
    }
  }
  .listing-footer {
  }

  &.for-sale-listing {
    &.helicopter .listing-image {
      background-image: url("~images/listings/sale-helicopter.png");
    }
    &.jet .listing-image {
      background-image: url("~images/listings/sale-jet.png");
    }
    &.single-piston .listing-image {
      background-image: url("~images/listings/sale-single-piston.png");
    }
    &.turboprop .listing-image {
      background-image: url("~images/listings/sale-turboprop.png");
    }
    &.twin-piston .listing-image {
      background-image: url("~images/listings/sale-twin-piston.png");
    }
  }

  &.wanted-listing {
    &.helicopter .listing-image {
      // background-image: url("~images/listings/wanted-helicopter.png");
      background-image: url("~images/listings/wanted-Images-helicopter.jpg");
    }
    &.jet .listing-image {
      // background-image: url("~images/listings/wanted-jet.png");
      background-image: url("~images/listings/wanted-Image-jat.jpg");
    }
    &.single-piston .listing-image {
      // background-image: url("~images/listings/wanted-single-piston.png");
      background-image: url("~images/listings/wanted-Images-single-piston.jpg");
    }
    &.turboprop .listing-image {
      // background-image: url("~images/listings/wanted-turboprop.png");
      background-image: url("~images/listings/wanted-Image-turboprop.jpg");
    }
    &.twin-piston .listing-image {
      // background-image: url("~images/listings/wanted-twin-piston.png");
      background-image: url("~images/listings/wanted-Images-twin-piston.jpg");
    }
  }

  &.featured-listing {
    .listing-header {
      padding-top: 20px;

      .listing-title {
        h3 {
          a {
            color: $planemover_black;
            &:hover {
              color: $planemover_red;
            }
          }
        }
      }
    }
  }
  &.subscriber-listing {
    .listing-buttons {
      max-width: 500px;
      margin: 0 0 0 auto;
    }
  }
  &.basic-listing {
    .btn {
      display: block;
    }
  }
}

.removed-page {
  .page-header {
    text-align: center;
    padding-top: 20vh;
    padding-bottom: 20vh;
    background-image: url("~images/listings/removed-header.jpg");
    background-position: center center;
    background-size: cover;

    H1 {
      color: $planemover_white;
      font-weight: bold;
      margin-bottom: 50px;
    }
  }
}
.also-interested {
  background-color: $planemover_gray;

  H3 {
    color: $planemover_red;
  }

  .similar-item {
    .listing-title {
      color: $planemover_black;
    }
  }
}

.filters-header {
  #filters-toggle {
    padding: 5px;
    background: transparent;
    border: 0;
    align-self: center;
    .svg,
    svg {
      width: 32px;
      height: 32px;
    }
  }
}
