@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/breakpoints";

.inbox-page {
  &__title {
    @include planemover_page_title;
    color: $planemover_red;

    & span {
      font-weight: 600;
    }
  }

  header {
    .action-buttons {
      margin-bottom: 15px;
      .btn {
        padding: 10px;
      }
    }
  }

  .inbox-table {
    margin: 0 15px;
    .header {
      border: 1px solid $planemover_gray;
      padding: 5px 0 5px 15px;
      color: $planemover_gray;
      .form-group {
        margin: 0;
        line-height: 26px;
        text-transform: uppercase;
        input {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
        .form-check-label {
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
    .item-row {
      cursor: pointer;
      margin-right: -15px;
      margin-left: -15px;
      border: 1px solid $planemover_gray;
      border-top: 0;
      @include planemover_font(14px, 1rem);
      .column-wrapper {
        display: flex;
        flex-wrap: wrap;
        border-left: 15px solid $planemover_white;
        padding: 10px 0;
      }
      .item-column {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .select-column {
        padding-right: 0;

        input {
          width: 16px;
          height: 16px;
        }

        .time-column {
          text-align: right;
          .time {
            padding-right: 15px;
          }
        }
      }

      .listing-column {
        .row {
          display: flex;
          justify-content: space-between;
        }
      }

      &.status-unread {
        .column-wrapper {
          border-left: 15px solid $planemover_red;
        }
        .from-name,
        .listing-column,
        .to-column,
        .message-column {
          font-weight: bold;
        }

        .status.label {
          text-transform: uppercase;
          color: $planemover_red;
        }
      }
    }
  }

  .filter-dropdown {
    .form-control {
      width: auto;
      display: inline-block;
      margin-left: 10px;
    }
  }

  .pagination {
    margin-left: 30px;
  }
}
