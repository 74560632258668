@import "../variables/colors";
@import "../variables/fonts";

body.home .app-header {
  position: absolute;
  width: 100%;
  background-color: transparent;
  z-index: 100;

  .app-menu-col {
    a,
    button {
      color: $planemover_red;
      .svg {
        fill: $planemover_red;
      }
      &:hover {
        color: $planemover_teal;
        .svg {
          fill: $planemover_teal;
        }
      }
    }

    .app-menu-top {
      a,
      button {
        color: $planemover_black;
        .svg {
          fill: $planemover_black;
        }
        &:hover {
          color: $planemover_teal;
          .svg {
            fill: $planemover_teal;
          }
        }
      }
    }
  }

  .mobile-menu-trigger {
    .svg {
      fill: $planemover_black;
    }

    &:hover {
      .svg {
        fill: $planemover_teal;
      }
    }
  }
}

.app-home {
  .section-image-column {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10vw;
    padding-bottom: 8vw;
  }
  .section-text-column {
    padding-top: 6vw;
    padding-bottom: 4vw;
  }

  a {
    color: $planemover_teal;
    &:hover {
      color: $planemover_red;
    }
  }

  .btn {
    background-color: $planemover_red;
    color: $planemover_white;
    border-radius: 3px;
    border: 0;
    display: block;
    padding: 5px 15px !important;
    @include planemover_button();
    text-transform: uppercase;
    margin-bottom: 15px;

    &:hover,
    &:focus {
      background-color: $planemover_teal;
    }
  }
  .section-header {
    font-size: 30px;
  }

  #home-search-row {
    padding-top: 20vw;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .homepage-foreground {
      position: absolute;
      max-width: 100%;
      z-index: 20;
    }

    .homepage-middleground {
      position: absolute;
      max-width: 100%;
      z-index: 2;
    }

    .search-pane {
      background-color: $planemover_red;
      color: $planemover_white;
      padding: 50px 30px 400px 30px;

      @media all and (min-width: 768px) {
        padding: 100px 10% 400px 10%;
      }

      .search-pane-content {
        position: relative;
        z-index: 10;
      }
    }
    h1 {
      @include planemover_font(55px, 55px, 300);
      text-transform: uppercase;

      strong {
        font-weight: 600;
      }

      @media all and (min-width: 768px) {
        font-size: 72px;
        line-height: 72px;
      }
    }
    .search-subheader {
      margin-top: 20px;
      @include planemover_font(30px, 36px, 400);
    }
    .nav {
      margin-top: 50px;

      .nav-item {
        .nav-link {
          background-color: $planemover_black;
          color: $planemover_gray;

          @include planemover_font(18px, 22px, 600);
          font-family: Barlow Condensed;
          text-transform: uppercase;

          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 10px;

          border-top: 5px solid $planemover_red;

          .svg {
            max-width: 18px;
            max-height: 18x;
            fill: $planemover_gray;
            margin-right: 5px;
          }

          @media all and (min-width: 768px) {
            padding: 5px 10px;
            font-size: 24px;
            line-height: 29px;

            .svg {
              max-width: 24px;
              max-height: 24px;
              margin-right: 10px;
            }
          }

          &:hover,
          &:focus {
            background-color: $planemover_teal;
          }

          &.active {
            background-color: $planemover_white;
            color: $planemover_teal;
            border-color: $planemover_teal;

            .svg {
              fill: $planemover_teal;
            }
          }
        }
      }
    }

    .input-group {
      .form-control {
        border-radius: 0 !important;
        border: 0;
        outline: none !important;
        box-shadow: none !important;

        @include planemover_button();
        height: 45px;

        &:focus {
          outline: none !important;
        }
      }
      .btn {
        background-color: $planemover_white;
        margin: 0;
        padding: 0;
        border: 0;
        border-left: 3px solid $planemover_black;
        border-radius: 0;
        height: 45px;

        .svg {
          fill: $planemover_black;
          max-width: 24px;
        }

        &:hover,
        &:focus {
          //background-color: $planemover_teal;
          .svg {
            fill: $planemover_teal;
          }
        }
      }
    }
  }

  #home-featured-row {
    border-top: 10px solid $planemover_red;
    .slick-slider {
      background-color: $planemover_black;
      .slick-track {
        display: flex;
        align-items: center;
      }
      .slick-slide {
        color: $planemover_white;
        padding: 0 5px;
      }
      .slick-prev,
      .slick-next {
        width: 30px;
        height: 30px;
        background-color: $planemover_teal;
        border-radius: 50%;
        .svg {
          max-width: 12px;
          fill: $planemover_white;
          position: relative;
        }
        &:before {
          content: none;
        }
      }
      .slick-prev {
        left: 15px;
        z-index: 100;
        .svg {
          left: -2px;
        }
      }
      .slick-next {
        right: 15px;
        z-index: 100;
        .svg {
          left: 2px;
        }
      }

      @media all and (min-width: 768px) {
        .slick-prev,
        .slick-next {
          width: 50px;
          height: 50px;
          .svg {
            max-width: 30px;
          }
        }
        .slick-prev {
          left: 15px;
          .svg {
            left: -2px;
          }
        }
        .slick-next {
          right: 15px;
          .svg {
            left: 2px;
          }
        }
      }

      .slick-dots {
        position: relative;
        bottom: 0;
        background-color: $planemover_red;

        li {
          width: auto;
          height: auto;
          padding-bottom: 10px;

          A {
            display: inline-block;
            padding: 5px 10px 10px 10px;
            color: $planemover_white;
            border-bottom: 2px solid transparent;
          }

          &.slick-active {
            A {
              border-bottom: 2px solid $planemover_teal;
            }
          }
        }
      }
    }
  }

  #home-sale-row {
    padding-top: 10vw;
    padding-bottom: 8vw;
    background-color: $planemover_black;
    color: $planemover_white;
    background-image: url("~images/content/homepage/sale.jpg");

    .sale-links {
    }
  }

  #home-wanted-row {
    background-color: $planemover_black;
    color: $planemover_gray;
    .section-image-column {
      background-image: url("~images/content/homepage/wanted.jpg");
    }
    .wanted-links {
    }
  }

  #home-services-row {
    background-color: $planemover_black;
    color: $planemover_gray;
    .section-image-column {
      background-image: url("~images/content/homepage/services.jpg");
    }
    .services-links {
    }
  }

  &.fall {
    #home-search-row {
      .homepage-foreground {
        left: 40%;
        top: 8vw;
        width: 40vw;
        max-width: 500px;
        min-width: 200px;
      }
      .homepage-middleground {
        bottom: 0;
        width: 100%;
        object-fit: cover;
      }
      .search-pane {
        padding-bottom: 30vw;

        @media all and (min-width: 768px) {
          padding-bottom: 25vw;
        }
      }
    }
  }

  &.coastline {
    #home-search-row {
      .homepage-foreground {
        left: 15%;
        top: 8vw;
        width: 30vw;
        max-width: 400px;
        min-width: 150px;
      }
      .homepage-middleground {
        bottom: 0;
        width: 100%;
        object-fit: cover;
      }
      .search-pane {
        padding-bottom: 25vw;

        @media all and (min-width: 768px) {
          //padding-bottom: 300px;
        }
      }
    }
  }

  &.mountains {
    #home-search-row {
      .homepage-foreground {
        left: 22%;
        top: 12vw;
        width: 40vw;
        max-width: 500px;
        min-width: 200px;
      }
      .homepage-middleground {
        bottom: 0;
        width: 100%;
      }
      .search-pane {
        padding-bottom: 25vw;

        @media all and (min-width: 768px) {
          //padding-bottom: 450px;
        }
      }
    }
  }
}

.slick-track .featured-slide-type {
  background-color: $planemover_black;
  color: $planemover_white;

  .featured-image-col {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .featured-content {
    padding-top: 50px;
    padding-bottom: 50px;

    .featured-title {
      @include planemover_font(40px, 40px, 100);
      margin-bottom: 20px;
    }

    .featured-description {
      @include planemover_font(24px, 24px, 300);
      color: $planemover_gray;
    }

    .featured-company-row {
      margin-top: 50px;
      line-height: 1.25;
      display: flex;
      align-items: center;
    }
    // .featured-company-logo-col {
    // 	background-color: $planemover_white;
    // 	padding: 30px;
    // 	border-right: 10px solid $planemover_teal;
    // }

    .featured-company-logo-col {
      .company-logo {
        background-color: $planemover_white;
        border-right: 10px solid $planemover_teal;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .company-logo-centered {
          //position: absolute;
          padding: 15px;
          display: flex;
          flex: 1 0 calc(25% - 10px);

          object-position: 50% 50%;
        }

        IMG {
          object-fit: contain;
        }

        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }

    .featured-type {
      font-size: 24px;
      font-weight: bold;
    }
    .featured-location {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .featured-phone,
    .featured-website {
      font-size: 18px;
    }
    .featured-read-more-row {
      text-align: center;
      margin-top: 30px;
    }
  }

  .btn {
    display: inline-block;
  }
}
