@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/breakpoints";

.rich-text-editor {
    border: 1px solid $planemover_gray;

    & .toolbarClassName {
        background: #f5f5f5;
    }

    & .editor {
        padding: 10px;
        min-height: 100px;

        & .public-DraftStyleDefault-block {
            margin: 0;
            margin-bottom: 1rem;
        }
    }
}
