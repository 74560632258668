@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/breakpoints";

.pagination-control {
  margin-top: 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include for-mobile-up {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .perpage {
    @include planemover_text;
    margin-right: 10px;
  }

  &__select {
    display: flex;
    align-items: center;
    .select-control {
      svg {
        vertical-align: unset;
      }
    }
  }

  &__pagination {
    margin-bottom: 20px;
    @include for-mobile-up {
      margin-left: 30px;
      margin-bottom: 0;
    }
    .pagination {
      margin: 0;
      @include planemover_font(24px);

      & > li {
        margin: 0 10px;
        padding-bottom: 10px;
        a {
          cursor: pointer;
          padding: 0 5px 10px 5px;
          color: $planemover_black;
          outline: none;
        }

        &.active {
          border-bottom: 2px solid $planemover_teal;
        }

        &.next, &.previous {
          a {
            color: $planemover_teal;
          }
          &.disabled {
            display: none;
          }
        }

        &.break-me {
          display: none;
        }
      }
    }
  }
}