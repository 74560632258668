@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/breakpoints";

#app-manage-table,
#app-manage-table-archive {
  padding: 70px 15px;
  font-family: $planemover_barlow;

  & .title {
    @include planemover_page_title();
    color: $planemover_red;
    font-weight: normal;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
}

#app-manage-table {
  .bottom-content {
    text-transform: uppercase;
    margin-top: 1rem;

    p {
      margin: 0;

      & strong {
        color: $planemover_red;
      }

      & span {
        text-decoration: underline;
        color: $planemover_teal;
      }
    }
  }
}

#app-manage-table-info {
  // custom stepper css
  .info-custom-stepper {
    .display {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));

      @include for-mobile-up {
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
      }

      @include for-tablet-portrait-up {
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
      }
    }

    .d-grid {
      display: flex;
    }

    .step {
      position: relative;
      background: $planemover_gray;
      border-radius: 30px;
      cursor: pointer;
      height: 25px;
      width: 40px;

      @include for-mobile-up {
        height: 30px;
        width: 35px;
        position: relative;
        background: $planemover_gray;
        border-radius: 30px;
        cursor: pointer;
      }

      &.active {
        background: $planemover_teal;
      }

      & svg {
        top: 4px;
        right: 3px;
        color: $planemover_white;
        position: absolute;

        @include for-mobile-up {
          position: absolute;
          top: 7px;
          right: 7px;
          color: $planemover_white;
        }
      }
    }

    label {
      width: 50%;
      height: 1px;
      margin: 0px !important;
      background: $planemover_gray;
    }

    span {
      width: 50%;
    }
  }

  // custom stepper css
}

#app-manage-table,
#app-manage-table-archive,
#app-manage-table-info,
#app-manage-table-wanted-info,
#app-manage-table-services-info {
  .m-b-15 {
    margin-bottom: 15px;
  }

  .custom_checkbox {
    display: block;
    position: relative;
    padding-left: 15px;
    padding-bottom: 14px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #eee;
      border: 1px solid #e2e2e2;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    & input:checked ~ .checkmark {
      background-color: $planemover_teal;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    & input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    & .checkmark:after {
      left: 4px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
    }
  }
}

#app-manage-table-info,
#app-manage-table-wanted-info,
#app-manage-table-services-info {
  & .stepper {
    textarea {
      border-radius: 0;
    }

    .text-center {
      text-align: center;
    }

    .text-green {
      color: $planemover_teal;
    }

    label {
      margin: 0;
      margin-right: 5px;
      align-self: center;
    }

    .subHeader {
      font-weight: 600;
      font-size: 20px;
      line-height: 40px;
    }

    .header-content {
      display: block;

      @include for-mobile-up {
        display: flex;
      }

      h5 {
        align-self: flex-end;
      }
    }

    .stepper_btns {
      margin-top: 20px;
      text-align: center;
    }

    .form-row {
      margin-right: -15px;
      margin-left: -15px;
      align-items: flex-start;

      & > .col,
      & > [class*="col-"] {
        padding-right: 15px;
        padding-left: 15px;
      }

      .form-group.d-flex {
        flex-wrap: wrap;

        .form-label {
          flex-shrink: 1;
        }
        .form-control {
          flex-grow: 1;
        }
        .invalid-feedback {
          flex-basis: 100%;
          width: 100%;
        }

        .custom-control.custom-checkbox {
          padding-top: 3px;
        }
      }
    }

    input,
    input.form-control,
    select {
      width: 100px;
      height: 30px;
      border-radius: 0;
      padding: 2px 5px;
      align-self: center;
      //text-transform: uppercase;
      font-weight: 500;

      span {
        text-transform: capitalize;
      }
    }

    input[type="file"] {
      width: auto;
    }

    &_1 {
      &-sell {
        .price {
          .form-group {
            margin: 0;
          }

          & .form-control {
            border-radius: 0;
          }

          select {
            width: 60px;
          }
        }

        &.upload-section {
          .video {
            input {
              width: 250px;
            }
          }

          svg {
            align-self: center;
            color: $planemover_gray;
            margin-left: 5px;
          }

          .spec-sheet-row {
            .single-image-upload .fileInput {
              width: auto;
            }
          }
        }
      }

      &-who {
        &-title {
          input {
            width: 250px !important;
          }
        }
      }
    }
  }
}

.listings-manage {
  .loading {
    text-align: center;
    padding: 15vh 15vw;

    .spinner-border {
      border-color: $planemover_teal;
      border-right-color: transparent !important;
    }
  }
}

.highlights-row {
  display: grid;
  grid-auto-flow: column;
  //grid-template-rows: repeat(6, 1fr);
}
.custom-checkbox-2{
  display: block;
  position: relative;
  border-radius: 3px;
  .checkmark{
    position: relative;
    width: 15px;
    height: 15px;
    display: block;
    background-color: #fff;
    border:1px solid #ddd;
    &::after{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      color:#fff;
      background:url(../../images/check-solid.svg) no-repeat center center;
      background-size: 80% auto;
      opacity: 0;
      transition: all 0.2s ease;
    }
  }
  input{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    &:checked{
      ~ .checkmark{
        background-color: #500a05;
        border:1px solid #500a05;
        &::after{
          opacity: 1;
        }
      }
    }
  }
  
}


