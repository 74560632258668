@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/breakpoints";

.admin-homePage {
  padding: 70px 0;
  font-family: $planemover_barlow;

  @include for-mobile-up {
    padding: 50px 0;
    font-family: $planemover_barlow;
  }

  .welcome {
    @include planemover_font(24px, 28.8px, 500);
    color: $planemover_red;
    font-weight: normal;
    padding-bottom: 20px;
    text-transform: uppercase;
  }

  .notifications {
    margin: 0;
    border: 2px solid #e2e2e2;
    padding: 10px 0;

    @include for-mobile-up {
      border: 2px solid #e2e2e2;
      padding: 10px 0;
    }

    &_content {
      display: block;

      @include for-mobile-up {
        display: flex;
      }

      & div > p {
        margin: 0;
        padding: 5px 0;
      }
    }

    &_list {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .notification {
        margin-bottom: 10px;
        A {
        }
        &_favorite {
        }

        SVG,
        .svg {
          fill: $planemover_gray;
          width: 16px;
          height: 16px;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    &_btn {
      text-align: center;
      margin-top: 30px;

      @include for-mobile-up {
        align-self: flex-end;
      }
    }
  }

  .dashboard {
    margin: 30px 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    width: 100%;

    @include for-mobile-up {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 15px;
      margin: 30px 0;
      width: 100%;
    }

    &_box {
      padding: 15px 0;

      &.border-green {
        border: 2px solid #009490;
      }

      &.border-black {
        border: 2px solid #000;
      }

      &.border-red {
        border: 2px solid #6d0020;
      }

      h1 {
        font-size: 3em;
        font-weight: bold;
      }

      span {
        font-size: 1.5em;
        text-transform: uppercase;
        margin-bottom: 0.5rem;

        &.subtitle {
          font-size: 1em;
        }
      }
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;

      .inner {
        display: flex;
        flex-direction: column;
        background-color: rgba(178, 181, 182, 0.95);
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;

        H2 {
          font-weight: 500;
          color: $planemover_red;
          margin-bottom: 15px;
        }

        .btn {
          padding: 5px 8vw;
        }
      }
    }
  }

  .general_buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-gap: 20px;
    margin: 15px;
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include for-mobile-up {
      display: grid;
      margin: 0;
      grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
      grid-gap: 20px;
    }

    @include for-desktop-up {
      display: grid;
      margin: 0;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }

    @include for-tablet-landscape-up {
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    }

    .disclaimer {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: uppercase;
    }
  }
}
