.plans {
  .plans-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 40px;
    margin-top: 40px;
    margin-left: -15px;
    margin-right: -15px;
    @include for-mobile-up {
      margin-left: 0px;
      margin-right: 0px;
      grid-gap: 20px;
    }

    @include for-mobile-up {
      grid-template-columns: repeat(2, 1fr);
    }

    @include for-tablet-portrait-up {
      grid-template-columns: repeat(3, 1fr);
    }

    @include for-desktop-up {
      grid-template-columns: repeat(5, 1fr);
    }

    .plan-card {
      box-sizing: border-box;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include for-mobile-up {
        padding: 12px;
        border: 1px solid #c4c4c4;
      }

      .title {
        font-weight: 300;
        color: $planemover_red;
      }

      .plan-price {
        font-weight: 600;
        color: $planemover_teal;
        margin-bottom: 12px;
        text-transform: lowercase;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .plan-list {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .description {
          height: 100%;
          border-bottom: 1px solid #25282a;
          @include planemover_font(14px, 17px, 400);
          padding-bottom: 20px;

          ul {
            padding-left: 15px;
            margin-bottom: 5px;

            li {
              margin-bottom: 5px;
            }
          }

          p {
            margin-bottom: 0;
          }
        }

        .promotions {
          margin-top: 12px;
          @include planemover_font(14px, 25px, 400);
          &-title {
            font-weight: 600;
            text-transform: uppercase;
          }
          .external {
            margin-top: 22px;
          }
        }
      }

      &-silver {
        background: rgba(196, 196, 196, 0.25);
      }
      &-gold {
        background: rgba(196, 196, 196, 0.5);
      }
      &-platinum {
        background: rgba(196, 196, 196, 0.75);
      }
      &-titanium {
        background: #c4c4c4;
      }

      &.most-popular {
        @include for-desktop-up {
          margin-top: -40px;
          .plan-price {
            margin-bottom: 22px;
          }
        }
        .header {
          margin-top: 12px;
        }

        .most-popular-header {
          background: $planemover_teal;
          color: $planemover_white;
          width: calc(100% + (30px * 2));
          text-align: center;
          margin: -30px -30px 0 -30px;
          text-transform: uppercase;
          padding: 5px 0;
          @include for-mobile-up {
            width: calc(100% + (12px * 2));
            margin: -12px -12px 0 -12px;
          }
        }
      }

      &.plan-disabled {
        .title,
        .plan-price,
        .plan-list {
          color: $planemover_gray !important;
        }
      }
    }
  }
}
