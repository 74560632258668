.company-detail {
  .company-reps {
    background-color: $planemover_gray;

    .btn {
      display: block;
      width: 100%;
      text-align: left;
      outline: none;
      H2 {
        vertical-align: middle;
        .caret {
          display: inline-block;
          margin-left: 10px;
          position: relative;
          top: -3px;
          SVG {
            fill: $planemover_white;
            margin: 0;
            transition: transform 250ms ease-in-out;
            transform: rotate(180deg);
          }
        }
      }
    }

    .reps-columns {
      padding-top: 30px;
      transition: height 250ms ease-in-out;

      .rep-column {
        .app-avatar {
          font-size: 32px;
          margin-bottom: 15px;
        }

        .rep-name {
          margin-bottom: 10px;
          color: $planemover_white;
          text-transform: none;
          font-size: 18px;
          font-weight: 500;
        }
        .rep-phone {
          margin-bottom: 10px;
          A {
            color: $planemover_red;

            &:hover,
            &:focus {
              color: $planemover_white;
            }
          }
        }
      }
    }

    .closed {
      .btn H2 .caret SVG {
        transform: rotate(180deg);
      }
      .reps-columns {
        height: 0px;
      }
    }
    .open {
      .btn H2 .caret SVG {
        transform: rotate(0deg);
      }
      .reps-columns {
        height: auto;
      }
    }
  }
}
