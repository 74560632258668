@import "../variables/breakpoints";
@import '../variables/colors';
@import '../variables/fonts';

.standalone-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  @include for-tablet-portrait-up {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-top: 50px;
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: solid 1px $planemover_gray;
    @include for-tablet-portrait-up {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      width: unset;
      justify-content: center;
    }

    & > li {
      padding-bottom: 15px;
      width: 33.33%;
      text-transform: uppercase;
      cursor: pointer;
      text-align: center;
      @include planemover_text;
      white-space: nowrap;

      &.active {
        color: $planemover_red;
        border-bottom: 10px solid $planemover_red;
        .item-name {
          display: unset;
        }
      }
      @include for-tablet-portrait-up {
        padding: 0 40px 15px 40px;
        width: unset;
      }

      @include for-desktop-up {
        .item-name {
          padding-left: 0;
          display: unset;
        }
        .item-num {
          display: none;
        }
      }
    }
  }
}