.similar-card-list {
  background: $planemover_gray;
  padding-top: 45px;
  padding-bottom: 45px;
  .title {
    @include planemover_page_title;
    color: $planemover_red;
    margin-bottom: 30px;
    text-transform: uppercase;

    & span {
      font-weight: 600;
    }
  }

  .similar-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px 20px;

    @include for-tablet-portrait-up {
      grid-template-columns: repeat(2, 1fr);
    }

    @include for-tablet-landscape-up {
      grid-template-columns: repeat(3, 1fr);
    }

    .similar-card {
      border: none;
      background: none;
      &__img {
        border: none;
        border-radius: 0;
      }

      &__body {
        padding: 5px 0;
      }
    }
  }
}