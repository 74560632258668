@import "../variables/breakpoints";
@import "../variables/colors";
@import "../variables/fonts";
$linkTransition: all 250ms ease-in-out;

.app {
  display: flex;
  flex: 1;
  min-height: 100vh;
  overflow: hidden;

  @include planemover_text();
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: 200;

  strong {
    font-weight: 900;
  }
}

a,
button {
  color: $planemover_teal;
  transition: $linkTransition;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $planemover_red;
    transition: $linkTransition;
    text-decoration: none;
  }
}

ul,
ol {
  &.no-bullets {
    padding: 0;
    list-style-type: none;
  }
}

.svg {
  transition: $linkTransition;
  width: 32px;
  height: 32px;
}

@media (min-width: 576px) {
  //.container-half { max-width: calc(540px / 2); }
}

@media (min-width: 768px) {
  .container-half {
    max-width: calc(720px / 2);
  }
  .container-half-md-left {
    margin-right: 0;
  }
  .container-half-md-right {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .container-half {
    max-width: calc(960px / 2);
  }
}

@media (min-width: 1200px) {
  .container-half {
    max-width: calc(1140px / 2);
  }
}

.btn {
  @include planemover_font(16px, 1rem, 500);
  letter-spacing: 0.1rem;
  text-transform: uppercase;

  padding: 5px 20px;
  background-color: $planemover_gray;
  border: 1px solid $planemover_gray;
  box-shadow: none;
  border-radius: 3px;
  transition: $linkTransition;
  &:disabled {
    cursor: unset;
  }

  &:hover,
  &.focus,
  &:focus {
    box-shadow: none;
    transition: $linkTransition;
    background-color: $planemover_black;
    border-color: $planemover_black;
  }

  svg,
  .svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  &.btn-sm {
    font-size: 0.8em;
    padding: 5px 15px;

    svg,
    .svg {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  &.btn-lg {
    @include planemover_font(18px, 1rem, 500);
    padding: 10px 30px;
  }

  &.btn-wide {
    display: block;
    width: 100%;
  }

  &.btn-rounded,
  &.btn-round,
  &.rounded,
  &.round {
    border-radius: 3px !important;
  }
  &.btn-square,
  &.btn-squared,
  &.square,
  &.squared {
    border-radius: 0 !important;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    background-color: $planemover_gray;
    border-color: $planemover_gray;
    color: $planemover_white;

    &:hover,
    &:focus {
      background-color: $planemover_gray;
      border-color: $planemover_gray;
    }
  }

  &.btn-red,
  &.btn-primary {
    &:not(:disabled):not(.disabled) {
      background-color: $planemover_red;
      border-color: $planemover_red;
      color: $planemover_white;

      &:hover,
      &:focus {
        background-color: $planemover_teal;
        border-color: $planemover_teal;
      }

      &.btn-outline,
      &.outline {
        background-color: transparent !important;
        color: $planemover_red !important;
      }
    }
  }

  &.btn-gray,
  &.btn-secondary {
    &:not(:disabled):not(.disabled) {
      background-color: $planemover_gray;
      border-color: $planemover_gray;
      color: $planemover_white;

      &:hover,
      &:focus {
        background-color: $planemover_black;
        border-color: $planemover_black;
      }

      &.btn-outline,
      &.outline {
        background-color: transparent !important;
        color: $planemover_gray !important;
      }
    }
  }

  &.btn-green,
  &.btn-tertiary {
    &:not(:disabled):not(.disabled) {
      background-color: $planemover_teal;
      border-color: $planemover_teal;
      color: $planemover_white;

      &:hover,
      &:focus {
        background-color: $planemover_red;
        border-color: $planemover_red;
      }

      &.btn-outline,
      &.outline {
        background-color: transparent !important;
        color: $planemover_teal !important;
      }
    }
  }

  &.btn-white {
    background-color: $planemover_white !important;

    &:not(:disabled):not(.disabled) {
      border-color: $planemover_black;
      color: $planemover_black;

      svg,
      .svg {
        color: $planemover_black;
        fill: $planemover_black;
      }

      &:hover,
      &:focus {
        border-color: $planemover_teal;
        color: $planemover_teal;
        svg,
        .svg {
          color: $planemover_teal;
          fill: $planemover_teal;
        }
      }

      &.btn-outline,
      &.outline {
        background-color: transparent !important;
        color: $planemover_black !important;
      }
    }

    // Disabled Version
    &:disabled,
    &.disabled {
      border-color: $planemover_gray;
      color: $planemover_gray;

      svg,
      .svg {
        color: $planemover_gray;
        fill: $planemover_gray;
      }

      &.btn-outline,
      &.outline {
        background-color: transparent !important;
        color: $planemover_gray !important;
      }
    }
  }

  &.btn-black {
    background-color: $planemover_black !important;

    &:not(:disabled):not(.disabled) {
      border-color: $planemover_black;
      color: $planemover_white;

      svg,
      .svg {
        color: $planemover_white;
        fill: $planemover_white;
      }

      &:hover,
      &:focus {
        border-color: $planemover_teal;
        color: $planemover_teal;
        svg,
        .svg {
          color: $planemover_white;
          fill: $planemover_white;
        }
      }

      &.btn-outline,
      &.outline {
        background-color: transparent !important;
        color: $planemover_white !important;
      }
    }

    // Disabled Version
    &:disabled,
    &.disabled {
      border-color: $planemover_gray;
      color: $planemover_gray;

      svg,
      .svg {
        color: $planemover_gray;
        fill: $planemover_gray;
      }

      &.btn-outline,
      &.outline {
        background-color: transparent !important;
        color: $planemover_gray !important;
      }
    }
  }

  &.btn-icon,
  &.btn-primary.btn-icon {
    &:not(:disabled):not(.disabled) {
      padding: 5px;
      margin: 0;
      border: 0;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }

  &.btn-invisible {
    padding: 0;
    margin: 0;
    border: 0 !important;
    color: $planemover_black;
    background-color: transparent !important;
  }
}

button {
  background: transparent;
  border: 0;
  border-radius: 0;
  outline: none;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }
}

button.btn-link {
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  color: $planemover_teal;
}

select,
select.form-control {
  border-radius: 0;

  &:focus {
    box-shadow: none;
    outline: 0;
    border-color: $planemover_teal;
  }
}

ul.tabs {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-top: 50px;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid $planemover_gray;

  @include for-tablet-portrait-up {
    margin-top: 0;
  }

  @include for-tablet-portrait-up {
    margin-left: 0;
    margin-right: 0;
    border-bottom: 0;
  }

  &.centered {
    justify-content: center;
  }

  li {
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    border-bottom: 1px solid $planemover_gray;
    @include planemover_text;

    a {
      color: $planemover_black;
      //padding: 15px 50px 15px 50px;
      padding: 1vh 3vw;
      display: block;
    }

    .item-name {
      //padding-left: 10px;
      display: none;
      font-weight: 500;
    }

    &.active {
      color: $planemover_red;
      border-bottom: 10px solid $planemover_red;

      a {
        color: $planemover_red;
      }
      .item-num {
        margin-right: 10px;
      }
      .item-name {
        display: unset;
        margin-right: 10px;
      }
    }
    @include for-desktop-up {
      .item-name {
        padding-left: 0;
        display: unset;
      }
      .item-num {
        display: none;
      }
    }
  }
}

.app-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  // min-width: 920px;
  min-height: 100vh;
  min-width: 1px;
  position: relative;
}

.app-content {
  padding: 50px 0;

  @include for-tablet-landscape-up {
    padding: 125px 5%;
  }

  // flex-grow: 1;
}

#admin-notification,
#app-manage-table-info,
#app-manage-table-wanted-info,
#app-manage-table-services-info {
  padding: 70px 0;
  font-family: $planemover_barlow;

  & .title {
    @include planemover_page_title();
    color: $planemover_red;
    font-weight: normal;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
}

.notify {
  @include planemover_sidebar_button;
}

.white-space-pre {
  //white-space: pre-wrap;
}

.content-page {
  .header-row {
    padding-top: 5vw;
    padding-bottom: 3vw;
    h1 {
      color: $planemover_red;
      margin-bottom: 20vh;
    }
    h2 {
      color: $planemover_red;
    }
    @include for-tablet-portrait-up {
      h1 {
        margin-bottom: 0;
      }
    }
  }

  ul,
  ol {
    margin-bottom: 20px;

    li {
      margin-bottom: 15px;
    }
  }
}

.form-control {
  border: solid $planemover_gray 1px;
  border-radius: 0;
}

.app-avatar .UserAvatar {
  img {
    display: inline-block !important;
  }
  &.UserAvatar--dark {
    color: $planemover_white !important;
  }
  &.UserAvatar--light {
    color: $planemover_black !important;
  }
}

.editable-input-group {
  margin: 12px 0;
  @include planemover_text;
  min-height: 30px;
  display: flex;
  //align-items: flex-end; // Removed to fix alignment on Site Settings > Plans page
  flex-wrap: nowrap;

  &:last-child {
    margin-bottom: 0px;
  }

  &-label {
    margin: 0 10px 0 0;
    @include planemover_text;
    color: #212529;
    border: 1px solid transparent;
    display: block;
    flex-shrink: 0;
  }

  .form-control {
    height: 25px;
    padding: 0 5px;
  }

  &-reading {
    display: flex;
    align-items: center;
    align-items: flex-start;

    .value {
      color: $planemover_teal;
      margin-right: 15px;
      border: 1px solid transparent;
      display: block;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;

      p {
        margin: 0;
      }
    }

    .edit {
      fill: $planemover_teal;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }
  }

  &-editing {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-wrap: nowrap;

    .control {
      margin-right: 10px;
      display: block;
      flex-grow: 1;
    }
    .done {
      fill: $planemover_teal;
      width: 24px;
      height: 24px;
    }
  }
}

.text-red {
  color: $planemover_red;
}

.text-green {
  color: $planemover_teal;
}
