@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/breakpoints";
@import "../variables/buttons";

.my-saved-searches {
  &__title {
    @include planemover_page_title;
    color: $planemover_red;

    & span {
      font-weight: 600;
    }
  }

  .search-block {
    border: 1px solid $planemover_gray;
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (min-width: 1500px) {
      max-width: 1330px;
    }

    &__header {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: column;

      @include for-mobile-up {
        padding: 15px 25px;
        flex-direction: row;
      }
    }

    &__title {
      order: 1;
      min-height: 35px;
      display: flex;
      align-items: center;

      .input-control {
        border: 1px solid $planemover_gray;
        outline: none;
        @include planemover_text;
        color: $planemover_black;
      }
      .editable-input-control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @include for-mobile-up {
          justify-content: flex-start;
          width: unset;
        }

        &__label {
          @include planemover_subheader;
          color: $planemover_teal;
        }

        &__button {
          padding: 0;
          margin-left: 15px;
          background: none;
          border: none;
          svg {
            width: 20px;
            fill: $planemover_teal;
          }
        }
      }
      
    }

    &__actions {
      order: 3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @include for-mobile-up {
        order: 2;
        justify-content: flex-start;
        width: unset;
      }

      &__label {
        color: $planemover_teal;
        margin-right: 25px;
        & > * {
          text-decoration: underline;
        }
        &:after {
          content: '>';
          padding-left: 10px;
        }
      }

      &__button {
        padding: 3px 10px;
        @include planemover_button($planemover_gray, $planemover_gray-ligthdark, $planemover_gray-dark);
      }
    }

    &__search-filter {
      order: 2;
      width: 100%;

      @include for-mobile-up {
        order: 3;
      }

      &__label {
        @include planemover_sidebar_button;
        margin-right: 10px;
        color: $planemover_black;
      }

      &__item {
        @include planemover_sidebar_button;
        color: $planemover_teal;
      }
    }

    &__slider {
      width: 100%;
      padding: 10px 0 30px 0;
      // margin: 0 -10px;

      .search-slider {
        .slick-arrow {
          width: 35px;
          height: 35px;
          background: rgba(37, 40, 42, 0.75);
          z-index: 10;
          svg {
            width: 16px;
            fill: $planemover_white
          }

          &.slick-disabled {
            display: none;
          }

          &.slick-prev {
            left: 0;
            &:before {
              content: none;
            }
          }
          &.slick-next {
            right: 0;
            &:before {
              content: none;
            }
          }
        }

        .slick-list {
          padding-left: 10px;
          .slick-slide {
            padding: 0 15px;
            max-width: 350px;
          }
        }
      }
    }

    &__list {
      padding: 0 15px 20px 15px;
      width: 100%;

      &__load-more {
        @include planemover_button-primary;
        text-transform: uppercase;
        color: $planemover_white;
        padding: 5px 15px;
      }
    }

    .search-card {
      border-radius: 0;
      border: 1px solid $planemover_gray;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      @include for-mobile-up {
        margin-bottom: 0;
      }

      &__img {
        border-radius: 0;
      }

      &__title {
        @include planemover_subheader;
        margin-bottom: 5px;
      }
      
      &__text {
        @include planemover_text;
        margin-bottom: 0;
      }

      &__info {
        @include planemover_text;
        color: $planemover_teal;
        text-decoration: underline;

        &:hover {
          color: $planemover_teal;
          text-decoration: underline;
        }
      }

      &__body {
        position: relative;
        padding: 15px;
        .like-button {
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          position: absolute;
          top: 15px; 
          right: 15px;
          cursor: pointer;
          @include planemover_subheader;
          color: $planemover_black;
          outline: none;
        }
      }
    }
  }
}