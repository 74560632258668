.message-modal {
  background: $planemover_red;
  padding: 30px;
  color: $planemover_white;

  .close {
    position: absolute;
    background-color: $planemover_red !important;
    opacity: 1 !important;
    border-radius: 50%;
    overflow: hidden;
    z-index: 10;
    top: -16px;
    right: -16px;
    padding: 0;
    background: none;
    border: none;
    width: 48px;
    height: 48px;
    padding: 5px;
    &:hover {
      
    }
    &:focus {
      outline: none;
    }
    .close-circle {
      width: 100%;
      height: 100%;
      fill: $planemover_gray;
    }
  }
  .message {
    @include planemover_page_title;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: center;

    & .bold {
      font-weight: 600;
    }
  }

  .actions {
    text-align: center;
    .btn {
      margin: 0 10px;

      &.btn-primary:hover {
        background-color: $planemover_teal-dark;
      }
    }
  }
}

.video-modal {
  .modal-dialog {
    max-width: 1140px !important;

    .video-player-wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
