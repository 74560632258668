.filters {
  .filters-pane {
    .filter-group {
      padding: 10px 20px;
      border-bottom: 1px solid $planemover_gray;

      h4 {
        margin: 0;
        color: $planemover_red;
        font-weight: 700;
        font-size: 22px;
        cursor: pointer;
        white-space: nowrap;

        .filter-group-count {
          display: inline-block;
          margin-left: 15px;
          color: $planemover_black;
          font-weight: 400;
          font-size: 14px;
          text-transform: initial;
          line-height: 18px;
        }
      }

      .svg,
      svg {
        fill: $planemover_red;
        margin-right: 15px;
        transform: rotate(180deg);
        max-width: 24px;
        margin-top: -4px;
      }

      &.is-expanded {
        .svg,
        svg {
          transform: rotate(0deg);
        }
      }

      .ReactCollapse--collapse {
        overflow: hidden !important;
      }

      .form-group {
        margin: 0;
        padding: 5px 20px 5px 40px;

        .custom-checkbox {
        }
        .custom-control-label::before {
          border-radius: 0 !important;
          border-color: $planemover_gray !important;
        }
        .custom-control-input:checked ~ .custom-control-label::before {
          border-radius: 0 !important;
          border-color: $planemover_teal !important;
          background-color: $planemover_teal !important;
        }
      }
    }
  }
}

#sidebar-filters {
  border-right: 1px solid $planemover_gray;
  padding-right: 15px;
  .filter-group-count {
    display: none !important;
  }

  .filters-pane {
    .filter-group {
      padding: 10px 0;

      .svg,
      svg {
        max-width: 16px;
      }
      .form-group {
        padding: 5px 5px 5px 0;
        white-space: nowrap;

        &.filter-dropdown {
          display: flex;
          align-items: center;
          overflow: hidden;
          LABEL {
            flex-basis: 0;
            max-width: 100%;
            margin: 0 10px 0 0;
          }
          SELECT {
            width: auto;
            flex-grow: 1;
            max-width: 100%;
            display: inline-block;
          }
        }

        &.filter-control {
          display: flex;
          align-items: center;
          overflow: hidden;
          LABEL {
            flex-basis: 0;
            max-width: 100%;
            margin: 0 10px 0 0;
          }
          .input-group {
            width: auto;
            flex-grow: 1;
            max-width: 100%;
          }
        }
      }

      &.scrollable {
        .ReactCollapse--content {
          max-height: 300px;
          overflow-y: auto !important;
        }
      }

      &.company-filters {
      }
    }
  }
}

#mobile-filters {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: $planemover_white;
  overflow-x: hidden;
  transition: opacity 1000ms ease-in-out;
  opacity: 0;
  display: none;
  flex-direction: column;

  &.mobile-filters-transition-enter {
    opacity: 0;
    display: flex;
  }
  &.mobile-filters-transition-enter-active {
    opacity: 1;
    transition: opacity 250ms;
    display: flex;
  }
  &.mobile-filters-transition-enter-done {
    opacity: 1;
    display: flex;
  }
  &.mobile-filters-transition-exit {
    opacity: 1;
    display: flex;
  }
  &.mobile-filters-transition-exit-active {
    opacity: 0;
    transition: opacity 250ms;
    display: flex;
  }
  &.mobile-filters-transition-exit-done {
    opacity: 0;
    display: none;
  }

  .filters-header {
    background-color: $planemover_gray;
    color: $planemover_white;
    padding: 10px 0;

    h2 {
      margin: 0;
    }

    .svg,
    svg {
      fill: $planemover_white;
    }

    #filters-toggle {
      .svg.arrow-right {
        width: 16px;
      }
    }
  }
  .filters-content {
    flex-grow: 1;
    overflow-y: auto;

    .filters-sort-pane {
      padding: 10px 20px;
      border-bottom: 1px solid $planemover_gray;
    }
  }
  .filters-footer {
    padding: 5px;
    border-top: 1px solid $planemover_gray;

    button {
      margin: 5px;
    }
  }
}
