.not-found-page {
    background-color: $planemover_black;
    color: $planemover_white;
    padding-top: 8vh;
        padding-bottom: 8vh;

    .page-header {
        padding-bottom: 5vh;

        .sub-header {
            text-transform: uppercase;
        }
    }

    .action-buttons-column {
        max-width: 250px;
    }

    .svg, SVG {
        fill: $planemover_gray;
        width: 100%;
        max-width: none;
        height: auto;
    }

    .btn {
        margin-bottom: 15px;
    }
}