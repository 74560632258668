@import "./colors";

$planemover_barlow: "Barlow Condensed", sans-serif;

@mixin planemover_button($color1: $planemover_teal, $color2: $planemover_teal-ligthdark, $color3: $planemover_teal-dark) {
  font-family: $planemover_barlow;
  font-size: 16px;
  color: $planemover_white;
  text-transform: uppercase;
  border: none;
  border-radius: 3px;
  outline: none;
  background: $color1;

  &:hover {
    background: $color2;
  }

  &:active {
    background: $color3;
  }

  &:focus {
    outline: none;
  }
}

@mixin planemover_button-primary() {
  @include planemover_button($planemover_teal, $planemover_teal-ligthdark, $planemover_teal-dark);
}

@mixin planemover_button-secondary() {
  @include planemover_button($planemover_red, $planemover_red-ligthdark, $planemover_red-dark);
}
