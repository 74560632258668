.rc-steps {
  .rc-steps-item {
    margin-right: 0;
    .rc-steps-item-container {
      cursor: unset;
      outline: none;
      .rc-steps-item-tail{
        padding: 0 12px 0 14px;
      }
      .rc-steps-item-icon {
        background: $planemover_gray;
        .rc-steps-icon {
          display: none;
          svg {
            width: 70%;
            height: 70%;
          }
        }
      }
      .rc-steps-item-content {
        .rc-steps-item-title{
          padding-right: 0;
        }
        .rc-steps-item-description {
          @include planemover_sidebar_button;
        }
      }
    }

    &.rc-steps-item-active {
      .rc-steps-item-container {
        .rc-steps-item-icon {
          background: $planemover_teal;
          border-color: $planemover_teal;
        }
        .rc-steps-item-content {
          .rc-steps-item-description {
            color: $planemover_teal;
          }
        }
      }
    }

    &.rc-steps-item-finish {
      .rc-steps-item-container {
        cursor: pointer;
        .rc-steps-item-tail{
          &:after {
            background-color: $planemover_teal;
          }
        }
        .rc-steps-item-icon {
          background: $planemover_teal;
          border-color: $planemover_teal;
          .rc-steps-icon {
            display: unset;
          }
        }
        .rc-steps-item-content {
          .rc-steps-item-description {
            color: $planemover_teal;
          }
        }
      }
    }
  }
  
  &.rc-steps-label-vertical {
    .rc-steps-item-description {
      text-align: center;
    }
  }
}