.image-upload-modal {
  .modal-dialog {
    // max-width: 1372px;
    // width: 90vw;
    max-width: 400px;

    .modal-content {
      padding: 0;
      border-radius: 0;
      border: none;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);

      .modal-header {
        padding: 1rem 0;
        border: 0;

        .upload-status {
          .upload-success {
            color: $planemover_teal;
          }
          .upload-error {
            color: $planemover_red;
          }
        }
      }
      .modal-body {
        padding-top: 0;
        padding-bottom: 0;
      }

      .modal-footer {
        border: 0;
      }

      .uploads {
        margin: 0;
        padding: 0;
        max-height: 300px;
        overflow-y: auto !important;
        overflow-x: hidden;

        .upload {
          display: flex;
          border-bottom: 1px solid $planemover_gray;
          margin-left: -15px;
          margin-right: -15px;
          align-items: center;
          padding: 15px 0;

          .filename {
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            &:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 30px;
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 1) 100%
              );
            }

            .error-message {
              color: $planemover_red;
            }
          }

          .icon {
            padding-right: 30px;

            .spinner-border {
              width: 28px;
              height: 28px;
              border-width: 0.4em;
              border-color: $planemover_gray;
              border-right-color: transparent;
            }

            .icon-success,
            .icon-error {
              display: flex;
              width: 32px;
              height: 32px;
              overflow: hidden;
              padding: 5px;
              float: right;
              justify-content: center;
              align-items: center;
              border-radius: 50%;

              SVG {
                width: 20px;
                height: 20px;
                fill: $planemover_white;
              }
            }

            .icon-success {
              background-color: $planemover_teal;
            }

            .icon-error {
              background-color: $planemover_red;
            }
          }
        }
      }

      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 0;
        background: none;
        border: none;
        &:hover {
          background: none;
        }
        &:focus {
          outline: none;
        }
        .close-circle {
          fill: $planemover_gray;
        }
      }

      .footer {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;

        .btn {
          min-width: 170px;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.image-upload-panel {
  .title {
    .bold {
      font-weight: 600;
      font-size: 20px;
      line-height: 40px;
    }
  }

  .dropzone-container {
    display: flex;
    flex-wrap: wrap;

    .preview-card {
      opacity: 1;
    }

    .add-image {
      margin-top: 20px;
      width: 115px;
      height: 115px;
      background: $planemover_gray;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      cursor: pointer;
    }
  }
}

.preview-card {
  opacity: 0.5;
  margin: 20px 20px 0 0;
  color: $planemover_dark;

  .image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: move;
    background: $planemover_dark;
    width: 115px;
    height: 115px;

    &.primary {
      border: 3px solid $planemover_red;
      box-sizing: border-box;
    }

    &.feature {
      border: 3px solid $planemover_teal;
      box-sizing: border-box;
    }

    &:focus,
    &:hover {
      .image-overlay {
        display: block;
      }
      .image-trash {
        display: block;
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 148, 144, 0.5);
      display: none;
    }

    &-trash {
      width: 13px;
      height: 15px;
      position: absolute;
      top: 5px;
      right: 5px;
      fill: $planemover_white;
      cursor: pointer;
      display: none;
      z-index: 1;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .text {
    text-align: center;
  }
}
