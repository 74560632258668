@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/breakpoints";

.my-favorites {
  &__title {
    @include planemover_page_title;
    color: $planemover_red;

    & span {
      font-weight: 600;
    }
  }

  &__nav {
    width: 100%;
    display: flex;
    justify-content: center;
    @include for-tablet-portrait-up {
      position: absolute;
      top: 0;
    }

    &__list {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-top: 50px;
      margin-left: -15px;
      margin-right: -15px;
      border-bottom: solid 1px $planemover_gray;
      @include for-tablet-portrait-up {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        width: unset;
        justify-content: center;
      }

      & > li {
        padding: 0 5% 15px 5%;
        width: 33.33%;
        text-transform: uppercase;
        cursor: pointer;
        text-align: center;
        @include planemover_text;
        white-space: nowrap;

        &.active {
          color: $planemover_red;
          border-bottom: 10px solid $planemover_red;
          .item-name {
            display: unset;
          }
        }
        @include for-tablet-portrait-up {
          padding: 0 40px 15px 40px;
          width: unset;
        }

        @include for-desktop-up {
          .item-name {
            padding-left: 0;
            display: unset;
          }
          .item-num {
            display: none;
          }
        }
      }
    }
  }

  &__card-list {
    display: grid;
    grid-gap: 40px 20px;
  }

  &__card {
    border-radius: 0;
    border: none;
    position: relative;
    height: 100%;
    overflow: hidden;
    @include for-mobile-up {
      border: 1px solid $planemover_gray;
    }

    .btn-favorite {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    &__img {
      border-radius: 0;
    }

    .listing-image {
      background-color: $planemover_gray;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.sale {
      &.helicopter .listing-image {
        background-image: url("~images/listings/sale-helicopter.png");
      }
      &.jet .listing-image {
        background-image: url("~images/listings/sale-jet.png");
      }
      &.single-piston .listing-image {
        background-image: url("~images/listings/sale-single-piston.png");
      }
      &.turboprop .listing-image {
        background-image: url("~images/listings/sale-turboprop.png");
      }
      &.twin-piston .listing-image {
        background-image: url("~images/listings/sale-twin-piston.png");
      }
    }

    &.wanted {
      &.helicopter .listing-image {
        // background-image: url("~images/listings/wanted-helicopter.png");
        background-image: url("~images/listings/wanted-Images-helicopter.jpg");
      }
      &.jet .listing-image {
        // background-image: url("~images/listings/wanted-jet.png");
        background-image: url("~images/listings/wanted-Image-jat.jpg");
      }
      &.single-piston .listing-image {
        // background-image: url("~images/listings/wanted-single-piston.png");
        background-image: url("~images/listings/wanted-Images-single-piston.jpg");
      }
      &.turboprop .listing-image {
        // background-image: url("~images/listings/wanted-turboprop.png");
        background-image: url("~images/listings/wanted-Image-turboprop.jpg");
      }
      &.twin-piston .listing-image {
        // background-image: url("~images/listings/wanted-twin-piston.png");
        background-image: url("~images/listings/wanted-Images-twin-piston.jpg");
      }
    }

    &__text {
      @include planemover_text;
      margin-bottom: 0;
    }

    &__info {
      @include planemover_text;
      color: $planemover_teal;
      text-decoration: underline;

      &:hover {
        color: $planemover_teal;
        text-decoration: underline;
      }
    }

    .breadcrumbs {
      color: $planemover_gray;
      @include planemover_text(16px);
      padding: 0;
      margin: 0;
      display: flex;
      list-style-type: none;
      text-transform: uppercase;
      a {
        color: $planemover_teal;
        text-decoration: underline;
        &:hover,
        &:focus {
          color: $planemover_red;
        }
      }
      .breadcrumb-item {
        padding-left: 0;
        white-space: nowrap;
        &:last-child {
          &:after {
            content: "";
            padding: 0;
          }
        }
        &:before {
          content: none !important;
        }
        &:after {
          content: ">";
          padding: 0 5px;
        }
      }
    }

    &__body {
      position: relative;
      padding: 10px;

      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }
  }

  .filter-dropdown {
    .form-control {
      width: auto;
      display: inline-block;
      margin-left: 10px;
    }
  }

  .pagination {
    margin-left: 30px;
  }
}
