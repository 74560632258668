.welcome-advertise {
  .account-complete {
    width: 100%;
    border: 1px solid #B2B5B6;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .selected-plan {
    color: $planemover_teal;
    font-weight: 600;
    .plan-name {
      text-transform: uppercase;
    }
  }

  .listing-buttons {
    display: grid;
    grid-template-columns: repeat(1, 170px);
    gap: 20px;

    @include for-tablet-portrait-up {
      grid-template-columns: repeat(3, 170px);
    }
  }

  .start-browsing {
    margin-top: 30px;
  }

  .btn {
    width: 170px;
    padding-left: 0;
    padding-right: 0;
  }
}