.error-page {
    background-color: $planemover-red;

    .app-header {
        background-color: transparent;
      }

    .error-page-content {
        padding: 10vh 0;
        color: $planemover_white;
    
        h1 {
          color: $planemover_white;
        }
        A {
            color: $planemover_white;
            text-decoration: underline;

            &:hover {
                color: $planemover_teal;
            text-decoration: underline;
            }
        }
        .sub-header {
            text-transform: uppercase;
        }
    
        .airplane-graphic {
          margin-left: 5vw;
        }
        .svg,
        svg {
          fill: $planemover_gray;
          width: 100%;
          max-width: none;
          height: auto;
        }

        .page-content {
            margin-top: 30px;
            text-transform: uppercase;
        }

      }
}