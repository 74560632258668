@mixin for-mobile-up {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-big-desktop-up {
    @media (min-width: 1900px) {
        @content;
    }
}
