.contact-modal {
  background: $planemover_red;
  padding: 30px;
  color: $planemover_white;

  &-close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    background: none;
    border: none;
    &:hover {
      background: none;
    }
    &:focus {
      outline: none;
    }
    .close-circle {
      fill: $planemover_gray;
    }
  }

  &-text {
    @include planemover_page_title;

    & .bold {
      font-weight: 600;
    }
  }

  .contact-form {
    margin-top: 30px;
    &-label {
      font-weight: 500;
      font-size: 18px;
      color: $planemover_white;
    }

    &-input {
      border: solid $planemover_gray 1px;
      border-radius: 0;
      color: $planemover_dark;
    }

    &-error {
      color: $planemover_white;
    }
  }
}