.bread-crumb {
  color: $planemover_white;
  @include planemover_text;
  padding: 0;
  margin: 0;
  display: flex;
  list-style-type: none;
  text-transform: uppercase;
  li {
    &:last-child {
      &:after {
        content: "";
        padding: 0;
      }
    }
    &:after {
      content: ">";
      padding: 0 5px;
    }
  }
}