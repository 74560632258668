.sponsored-item {
  .sponsored-bar {
    background-color: $planemover_teal;
    color: $planemover_white;
    @include planemover_font(18px, 1.5, 600);
    padding: 3px 10px;
  }
  .listing-header {
      margin-top: 15px;
  }
}
