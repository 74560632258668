@import "../variables/colors";
@import "../variables/fonts";

.app-header {
  background-color: $planemover_black;
  color: $planemover_white;
  padding: 1vh 0;

  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: $planemover_red;
    overflow-x: hidden;
    transition: opacity 1000ms ease-in-out;
    opacity: 0;
    opacity: 1;

    &.mobile-menu-transition-enter {
      opacity: 0;
    }
    &.mobile-menu-transition-enter-active {
      opacity: 1;
      transition: opacity 250ms;
    }
    &.mobile-menu-transition-exit {
      opacity: 1;
    }
    &.mobile-menu-transition-exit-active {
      opacity: 0;
      transition: opacity 250ms;
    }

    .overlay-content {
      text-align: center;
      list-style-type: none;
      margin: 20px;
      padding: 0;

      a {
        padding: 4vh 0;
        text-decoration: none;
        font-size: 36px;
        @include planemover_sidebar_header($fontSize: 24px);
        color: $planemover_white;
        display: block;
        text-transform: uppercase;
        transition: 0.3s;
        border-bottom: 1px solid $planemover_gray;
        text-align: left;

        &:hover,
        &:focus {
          color: $planemover_teal;
        }
      }
    }

    .close-button {
      width: 32px;
      display: inline-block;
      .svg {
        fill: $planemover_white;
      }

      &:hover,
      &:focus {
        .svg {
          fill: $planemover_teal;
        }
      }
    }

    .search-button {
    }
    .language-button {
    }
  }

  .app-logo-col {
    display: flex;
    align-items: center;
    a {
      display: block;
    }
    .app-logo {
      width: 115px;
    }
  }

  .app-menu-col {
    padding-right: 0;

    a,
    button {
      @include planemover_sidebar_header();
      color: $planemover_gray;
      text-transform: uppercase;
      display: inline-block;
      padding: 10px 15px;

      .svg {
        fill: $planemover_gray;
      }

      &:hover {
        color: $planemover_teal;
        .svg {
          fill: $planemover_teal;
        }
      }
    }

    .app-menu-top {
      a,
      button {
        color: $planemover_white;
        .svg {
          fill: $planemover_white;
        }
        &:hover {
          color: $planemover_teal;
          .svg {
            fill: $planemover_teal;
          }
        }
      }
    }
  }

  .search-button .svg {
    width: 24px;
  }
  .avatar-button {
    .UserAvatar--inner {
      margin: auto;
      //color: $planemover_white !important;
    }
    &:hover {
      opacity: 0.75;
    }
  }

  .mobile-menu-trigger {
    width: 32px;
    display: inline-block;
    .svg {
      fill: $planemover_white;
    }
    &:hover {
      .svg {
        fill: $planemover_teal;
      }
    }
  }
}
