.listing-detail {
  .detail-header {
    background: $planemover_black;
    padding-top: 5vh;
    color: $planemover_gray;

    .listing-image {
      background-color: $planemover_gray;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      overflow: hidden;

      .corner-ribbon {
        width: 600px;
        position: absolute;

        margin: 0;
        padding: 5px;
        top: 75px;
        left: -200px;
        text-align: center;

        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);

        .headline {
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 3rem;
          font-weight: bold;
          line-height: 1;
        }
        .subtext {
          text-transform: uppercase;
        }

        &.draft {
          background: $planemover_gray;
          color: $planemover_white;
        }
        &.pending {
          .headline {
            font-size: 2rem;
          }
          background: $planemover_gray-dark;
          color: $planemover_white;
        }
        &.sold {
          background: $planemover_teal;
          color: $planemover_white;
        }
        &.removed {
          background: $planemover_red;
          color: $planemover_white;
        }
      }
    }
    #listing-image-mobile {
      margin-bottom: 30px;
    }

    .detail-header-info {
      margin-bottom: 15px;
      color: $planemover_gray;
    }

    .company-logo {
      background-color: $planemover_white;
      border-right: 10px solid $planemover_teal;

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .company-logo-centered {
        //position: absolute;
        padding: 15px;
        display: flex;
        flex: 1 0 calc(25% - 10px);

        object-position: 50% 50%;
      }

      IMG {
        object-fit: contain;
      }

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }

    .breadcrumbs {
      color: $planemover_gray;
      @include planemover_text(16px);
      padding: 0;
      margin: 0;
      display: flex;
      list-style-type: none;
      text-transform: uppercase;
      a {
        color: $planemover_white;
        text-decoration: underline;
        &:hover,
        &:focus {
          color: $planemover_teal;
        }
      }
      .breadcrumb-item {
        padding-left: 0;
        &:last-child {
          &:after {
            content: "";
            padding: 0;
          }
        }
        &:before {
          content: none !important;
        }
        &:after {
          content: ">";
          padding: 0 5px;
        }
      }
    }

    .title {
      @include planemover_page_title;
      color: $planemover_white;
      margin: 35px 0 10px 0;
      text-transform: uppercase;

      //   & span {
      //     font-weight: 600;
      //   }
    }

    .sale-types {
      @include planemover_button;
      color: $planemover_white;
      margin-bottom: 20px;
      text-transform: uppercase;

      .label {
        display: inline-block;
        margin-right: 5px;
      }
      .value {
        font-weight: 600;

        .separator {
          display: inline-block;
          padding: 0 5px;
        }
      }
      .values {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-block;
        li {
          display: inline-block;
          &:last-child .separator {
            display: none;
          }
        }
      }
    }

    .highlights-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      color: $planemover_gray;
      @include planemover_text;
      li {
        padding: 5px 0;
      }
    }
  }

  .detail-information {
    background: $planemover_red;
    color: $planemover_white;

    .detail-information-header {
      .detail-information-title {
        @include planemover_page_title;
        color: $planemover_white;
        text-transform: uppercase;
      }

      .btn-social-list {
        margin: 0 -10px;
        display: flex;
        justify-content: center;
        .btn-social {
          margin: 0 10px;
          padding: 0;
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: $planemover_white;
          outline: 0;
          @include planemover_sidebar_header;

          SVG {
            margin: 0;
          }
        }

        .btn-favorite {
          margin: 0 10px;
          SVG.heart-filled,
          .svg.heart-filled {
            fill: $planemover_white !important;
          }
        }
      }
    }

    .detail-information-stack {
      //width: 100%;
      margin-top: 30px;
      //margin-left: 0;

      .up {
        display: flex;
        justify-content: center;
        align-items: center;
        @include planemover_button;
        color: $planemover_white;
        padding-bottom: 10px;
        border-bottom: 1px solid $planemover_teal;

        svg {
          height: 20px;
          fill: $planemover_white;
          margin-right: 10px;
        }
      }

      .down {
        display: flex;
        justify-content: center;
        align-items: center;
        @include planemover_button;
        color: $planemover_white;
        padding-top: 10px;
      }
    }

    .detail-information-description {
      @include planemover_text;
      color: $planemover_white;
      //white-space: pre;
      P {
        margin-bottom: 10px;
      }

      .overflow-container {
        max-height: 10000px;
        transition: max-height 500ms ease-in-out;
        overflow: hidden;

        &.collapsed {
          max-height: 60px;
        }
      }
    }
    .detail-information-location {
      text-transform: uppercase;
    }
  }

  .detail-information-block {
    border-bottom: 1px solid $planemover_gray;

    h3 {
      @include planemover_subheader();
      text-transform: none;
      color: $planemover_red;
    }

    ul {
      margin: 0;
      padding-left: 18px;
      &.no-bullets {
        padding: 0;
        list-style-type: none;
      }

      &.inline {
        li {
          display: inline-block;
          margin-right: 15px;
        }
      }

      li {
        line-height: 1;
        padding: 5px 0;
      }
    }
  }

  .detail-content {
    line-height: 1;

    P {
      margin-bottom: 0.5rem;
    }
  }

  .detail-contact {
    .contact-title {
      @include planemover_page_title;
      color: $planemover_red;
      text-transform: uppercase;
      margin-bottom: 25px;
    }

    .company-contact-list {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        line-height: 1;
        padding-bottom: 10px;
      }
    }

    .company-logo {
      img {
      }
    }

    .company-time-list {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        line-height: 1;
        padding-bottom: 10px;
      }
    }
  }

  .listing-detail-similar {
    background: $planemover_gray;
    padding: 10px 0;

    .title {
      @include planemover_page_title;
      color: $planemover_red;
      margin-bottom: 30px;
      text-transform: uppercase;

      & span {
        font-weight: 600;
      }
    }

    .plan-model-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 30px 20px;

      @include for-tablet-portrait-up {
        grid-template-columns: repeat(2, 1fr);
      }

      @include for-tablet-landscape-up {
        grid-template-columns: repeat(3, 1fr);
      }

      .plan-model {
        border: none;
        background: none;
        &__img {
          border: none;
          border-radius: 0;
        }

        &__body {
          padding: 5px 0;
        }
      }
    }
  }

  &.for-sale-detail {
    .listing-image {
      background-color: $planemover_teal;
    }
    &.helicopter .listing-image {
      background-image: url("~images/listings/sale-helicopter.png");
    }
    &.jet .listing-image {
      background-image: url("~images/listings/sale-jet.png");
    }
    &.single-piston .listing-image {
      background-image: url("~images/listings/sale-single-piston.png");
    }
    &.turboprop .listing-image {
      background-image: url("~images/listings/sale-turboprop.png");
    }
    &.twin-piston .listing-image {
      background-image: url("~images/listings/sale-twin-piston.png");
    }
  }

  &.wanted-detail {
    .listing-image {
      background-color: $planemover_gray;
    }
    &.helicopter .listing-image {
      //background-image: url("~images/listings/wanted-helicopter.png");
      background-image: url("~images/listings/wanted-Images-helicopter.jpg");
    }
    &.jet .listing-image {
      //background-image: url("~images/listings/wanted-jet.png");
      background-image: url("~images/listings/wanted-Image-jat.jpg");
    }
    &.single-piston .listing-image {
     // background-image: url("~images/listings/wanted-single-piston.png");
     background-image: url("~images/listings/wanted-Images-single-piston.jpg");
    }
    &.turboprop .listing-image {
     // background-image: url("~images/listings/wanted-turboprop.png");
     background-image: url("~images/listings/wanted-Image-turboprop.jpg");
    }
    &.twin-piston .listing-image {
     // background-image: url("~images/listings/wanted-twin-piston.png");
     background-image: url("~images/listings/wanted-Images-twin-piston.jpg");
    }
  }

  &.service-detail {
    // .company-logo {
    //   margin-left: -15px;
    //   margin-right: -15px;
    //   padding: 40px 0px;
    //   height: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   background: $planemover_white;

    //   @include for-tablet-portrait-up {
    //     margin-right: 0;
    //     border-right: 7px solid $planemover_teal;
    //     width: 100%;
    //   }

    //   img {
    //     max-width: 170px;
    //   }
    // }

    .company-column {
      .service-tags {
        padding: 0;
        margin-top: 30px;
        list-style: none;
        LI {
          text-transform: uppercase;
          font-size: 24px;
          margin-bottom: 15px;
          color: $planemover_white;

          .bold {
            font-weight: bold;
          }
        }
      }
    }

    .action-column {
      border-top: 1px solid $planemover_teal;
      padding-top: 30px;

      @include for-tablet-landscape-up {
        border-top: 0;
        padding-top: 0;
        border-left: 1px solid $planemover_teal;
      }

      UL {
        //margin: 0;
        padding: 0;
        list-style: none;
      }
    }
  }
}
