.single-image-upload {
  .image-preview {
    margin: 10px 0;
    img {
      max-width: 200px;
      height: auto;
    }
  }
  button {
    margin-right: 10px;
  }
}

.single-image-brick-upload,
.multi-image-brick-upload {
  .file-input {
    display: none;
  }
  .image-upload-container {
    margin: -5px;
    display: flex;

    .image-brick {
      width: 120px;
      height: 120px;
      margin: 5px;
      padding: 0px;
      background: rgba(0, 148, 144, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
      border: none;
      position: relative;
      //position: absolute;

      img.image-preview {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }

      .image-delete {
        position: absolute;
        right: 5px;
        top: 5px;
        border-radius: 0;
        cursor: pointer;
        width: 12px;
        height: 14px;
        background-image: url("../../images/icons/delete.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        color: $planemover_dark;
        overflow: hidden;
        text-indent: -156px;

        &:after {
          content: "\f014";
          position: absolute;
        }
      }
    }

    .image-upload {
      button {
        //padding: 6px 23px;
        padding: 0;
        background-color: $planemover_teal-light;
        border-radius: 0;
        color: $planemover_white;
        font-weight: 300;
        font-size: 14px;
        margin: 5px;
        transition: all 0.2s ease-in;
        cursor: pointer;
        outline: none;
        border: none;
        width: 120px;
        height: 120px;

        &:hover {
          //background-image: url(./icon_upload.svg);
          background-color: $planemover_gray;
          background-repeat: no-repeat;
          background-position: 50% 23%;
          background-size: 25%;
        }
      }
    }
  }
}
.multi-image-brick-upload {
  .image-upload-container {
  }
}
