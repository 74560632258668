@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:300,400,500,600&display=swap");

// Global
@import "./sass/layout/global";

// Shared
@import "./sass/shared/standaloneTabs";
@import "./sass/shared/pagination";
@import "./sass/shared/richTextEditor";
@import "./sass/shared/table";
@import "./sass/shared/breadCrumb";
@import "./sass/shared/similarCardList";
@import "./sass/shared/imageCarousel";
@import "./sass/shared/socialButtons";
@import "./sass/shared/stepper";
@import "./sass/shared/spinner";
@import "./sass/shared/messageModal";
@import "./sass/shared/newsletterModal";
@import "./sass/shared/paymentIcons";
@import "./sass/shared/pills";
@import "./sass/shared/tabbedNav";
@import "./sass/shared/toggleSwitch";

// Layout
@import "./sass/layout/header";
@import "./sass/layout/footer";
@import "./sass/layout/sidebar";
@import "./sass/layout/cookies";

// Frontend
@import "./sass/frontend/account";
@import "./sass/frontend/registration/registration";
@import "./sass/pages/home";
@import "./sass/pages/advertise";
@import "./sass/pages/errors";
@import "./sass/pages/401";
@import "./sass/pages/403";
@import "./sass/pages/404";
@import "./sass/frontend/listings";
@import "./sass/frontend/details";
@import "./sass/frontend/sponsored";
@import "./sass/frontend/filters";
@import "./sass/frontend/pagination";
@import "./sass/frontend/company";
@import "./sass/frontend/contactForm";
@import "./sass/frontend/plans";

// Backend
@import "./sass/admin/dashboard";
@import "./sass/admin/company";
@import "./sass/admin/favorites";
@import "./sass/admin/accountSettings";
@import "./sass/admin/forSale";
@import "./sass/admin/siteSettings";
@import "./sass/admin/mySavedSearches";
@import "./sass/admin/promotions";
@import "./sass/admin/submitted-listings";
@import "./sass/admin/inbox";
@import "./sass/admin/messagePopup";
@import "./sass/admin/listImageUpload";
@import "./sass/admin/singleImageUpload";
