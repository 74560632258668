.btn-social-list {
  margin: 0 -10px;
  display: flex;
  justify-content: center;
  .btn-social {
    margin: 0 10px;
    padding: 0;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: $planemover_white;
    background-color: $planemover_gray !important;
    @include planemover_sidebar_header;

    &:hover,
    &.focus,
    &:focus {
      background-color: $planemover_black !important;
    }
  }

  .btn-heart {
    border: none;
    background: none;
    padding: 0;
    margin: 0 10px;
    color: $planemover_white;
    @include planemover_sidebar_header;
    font-size: 28px;
    outline: none;
  }
}
