@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/breakpoints";
@import "../variables/buttons";

.site-settings {
  &__title {
    @include planemover_page_title;
    color: $planemover_red;

    & span {
      font-weight: 600;
    }
  }

  

  &__subtitle {
    @include planemover_subheader;
    &.mb {
      margin-bottom: 35px;
    }
  }

  &__form-group {
    @include planemover_text;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: row;
    align-items: center;

    &.inline-md {
      flex-direction: column;
      align-items: flex-start;
      & > .textarea-control {
        margin-top: 15px;
      }
      @include for-tablet-portrait-up {
        flex-direction: row;

        & > .textarea-control {
          margin-top: 0px;
        }
      }
    }

    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }

    &--noInput {
      margin-top: 0;
      margin-bottom: 0;
    }

    label {
      margin-right: 10px;
      margin-bottom: 0;

      &.no-wrap {
        white-space: nowrap;
      }

      &.ml {
        margin-left: 11.64px;
      }

      &.ml-md {
        @include for-tablet-portrait-up {
          margin-left: 11.64px;
        }
      }
    }

    .input-control {
      border: solid 1px $planemover_gray;
      outline: none;
      color: $planemover_black;
      width: 100px;
      height: 25px;
      @include planemover_sidebar_subheader;
      padding-left: 10px;
      padding-right: 10px;
    }

    .textarea-control {
      border: solid 1px $planemover_gray;
      outline: none;
      color: $planemover_black;
      @include planemover_sidebar_subheader;
      padding: 3px 10px;
      min-height: 25px;
      word-wrap: break-word;

      ul {
        padding: 0;
        list-style-position: inside;
        margin: 0;
      }

      .bold {
        @include planemover_sidebar_button;
      }
    }

    .select-control {
      svg {
        vertical-align: unset;
      }
    }
  }

  &__save-button {
    margin-top: 20px;
    @include planemover_button-primary;
    padding: 3px 30px;
  }


  .subscription {
    &__block {
      border: solid 1px $planemover_gray;
      padding: 10px;

      &__subtitle {
        @include planemover_sidebar_header;
        margin-bottom: 15px;
      }

      .external-promotion {
        margin-top: 15px;
        @include for-tablet-portrait-up {
          margin-top: 0;
        }

        &__content {
          width: fit-content;
          .site-settings__form-group {
            margin-top: 0;
            justify-content: space-between;
          }
        }
      }

      .brief-description {
        min-height: 170px;
      }
    }
  }

  .aire-craft {
    &__form {
      display: flex;
      flex-direction: column;

      .make {
        padding-bottom: 20px;
        border-bottom: solid 1px $planemover_black;
      }

      .model {
        padding-top: 30px;
      }

      @include for-tablet-portrait-up {
        flex-direction: row;

        .make {
          padding-right: 65px;
          padding-bottom: 0;
          border-bottom: none;
          border-right: solid 1px $planemover_black;
        }

        .model {
          padding-top: 0;
          padding-left: 65px;
        }
      }
    }
  }

  .advertising {
    &__form {
      &__block {
        padding: 10px 0;
        border: solid 1px $planemover_gray;
        border-bottom: none;

        &:last-child {
          border-bottom: solid 1px $planemover_gray;
        }
      }
    }
  }

  .policies {
    &__form {
      &__block {
        padding: 10px 0;
        border: solid 1px $planemover_gray;
        border-bottom: none;

        &:last-child {
          border-bottom: solid 1px $planemover_gray;
        }
      }
    }
  }
}
