.image-carousel {
  background-color: $planemover_black;

  .image-item {
    position: relative;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    img {
      //filter: brightness(50%);
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    &::after {
      transition: opacity ease-in-out 150ms;
      content: "";
      background-color: $planemover_black;
      opacity: 0.5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 1;
    }

    .count {
      position: absolute;
      top: 10px;
      left: 15px;
      z-index: 10;
      color: $planemover_white;
    }

    .zoom-button {
      display: block;
      transition: opacity ease-in-out 150ms;
      opacity: 0;
      width: 75px;
      height: 75px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      border: none;
      cursor: pointer;
      border-radius: 50%;
      z-index: 10;

      svg {
        width: 28px;
        height: 28px;
        position: relative;
        left: 3px;
        top: 1px;
        fill: $planemover_white;
      }
    }

    &:hover {
      &::after {
        opacity: 0;
      }
      img {
        filter: brightness(100%);
      }

      .zoom-button {
        opacity: 1;
      }
    }
  }
}

.react-images__dialog {
  max-width: 100%;
  .react-images__view {
    padding: 5vw;
  }
}
