$planemover_barlow: "Barlow Condensed", sans-serif;

@mixin planemover_font($fontSize: 16px, $lineHeight: 19.2px, $fontWeight: 400) {
	font-family: $planemover_barlow;
	font-weight: $fontWeight;
	font-size: $fontSize;
	line-height: $lineHeight;
}

@mixin planemover_page_title($fontSize: 30px, $lineHeight: 36px) {
  @include planemover_font($fontSize, $lineHeight, 300);
}

@mixin planemover_subheader($fontSize: 20px, $lineHeight: 24px) {
	@include planemover_font($fontSize, $lineHeight, 600);
}

@mixin planemover_text($fontSize: 18px, $lineHeight: 21.6px) {
	@include planemover_font($fontSize, $lineHeight, 400);
}

@mixin planemover_button($fontSize: 20px, $lineHeight: 25px) {
	@include planemover_font($fontSize, $lineHeight, 400);
}

@mixin planemover_sidebar_header($fontSize: 18px, $lineHeight: 21.6px) {
	@include planemover_font($fontSize, $lineHeight, 600);
}

@mixin planemover_sidebar_subheader($fontSize: 16px, $lineHeight: 19.2px) {
	@include planemover_font($fontSize, $lineHeight, 300);
}

@mixin planemover_sidebar_links($fontSize: 18px, $lineHeight: 43.2px) {
	@include planemover_font($fontSize, $lineHeight, 400);
	letter-spacing: 10%;
}

@mixin planemover_sidebar_button($fontSize: 16px, $lineHeight: 19.2px) {
	@include planemover_font($fontSize, $lineHeight, 500);
}