.newsletter-modal {
  text-align: center;

  .close {
    position: absolute;

    overflow: hidden;
    z-index: 10;
    top: 5px;
    right: 5px;
    padding: 0;
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    padding: 5px;
    &:hover {
    }
    &:focus {
      outline: none;
    }
    .close-circle {
      width: 100%;
      height: 100%;
      fill: $planemover_gray;
    }
  }

  .content {
    padding: 30px;

    H2 {
      color: $planemover_red;
      line-height: 1;
    }

    .email-label {
      text-align: left;
      display: block;
      font-weight: bold;
    }

    FOOTER {
      margin-top: 30px;
    }
  }
}
