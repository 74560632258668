@import "../variables/colors";
@import "../variables/fonts";

.submitted-listing {
    padding: 120px 0;

    color: #000;
    @media (max-width: 768px) {
        .submitted-title {
            margin-left: 20px;
        }
        .submitted-container {
            width: 100% !important;
        }
        .submit-button-group {
            width: 100%;
            display: inline-grid;
            button {
                width: 170px;
                margin: auto !important;
                margin-top: 50px !important;
            }
        }
        .submited-details {
            margin-left: 20px !important;
        }
        .container {
            padding-left: 0px !important;
            padding-right: 0px !important;
            .col {
                padding-left: 0px !important;
                padding-right: 0px !important;
            }
            .row {
                padding-left: 15px !important;
                padding-right: 0px !important;
            }
        }
    }

    .row {
        margin-right: 0px !important;
    }
    .submit-btn-gray {
        height: 28px !important;
        width: 216px;
        background-color: $planemover_gray !important;
        border-color: $planemover_gray !important;
        padding: 0px 20px;
    }
    .submit-btn-gray:hover {
        background-color: $planemover_teal !important;
        border-color: $planemover_teal !important;
        padding: 0px 20px !important;
    }

    .title {
        line-height: 36px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: $planemover_red;
        font-size: 30px;
    }

    .submited-details {
        font-size: 18px;
        margin-bottom: 60px;
        margin-left: 0px;
        margin-right: 20px;
    }
    .submitted-container {
        .submitted-content {
            margin-left: 20px !important;
            margin-right: 20px !important;
        }
        div {
            font-size: 18px;
            h6 {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
    .submitted-listing {
        button {
            width: 210px;
            background-color: #c4c4c4 !important;
        }
        button:hover {
            background: #009490;
        }
    }
}
